import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getJob = async id => {
  const { data } = await getRequest(true, Endpoints.GET_JOB, {}, { id });
  return camelCaseKeys(data.data);
};

export default getJob;
