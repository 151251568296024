import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getAllSuppliers = async (page, keyword, categoryId) => {
  const { data } = await getRequest(true, Endpoints.ALL_SUPPLIERS, {
    page,
    keyword,
    category_id: categoryId,
  });
  return camelCaseKeys(data);
};

export default getAllSuppliers;
