import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import Pages from './constants/pages';
import { checkPagePermissions, createTransaction } from './services';
import { Loader } from './components';

const checkAuth = async path => {
  const token = localStorage.getItem('token');
  if (!token) return false;

  const route = Object.keys(Pages).find(r => {
    if (Pages[r].path && typeof Pages[r].path === 'string') {
      return Pages[r].path === path;
    }

    const reg = Pages[r].path();
    return Boolean(path.match(reg));
  });
  const accountType = localStorage.getItem('account_type');
  const hasAccess = Pages[route].hasAccess[accountType];

  if (!hasAccess) {
    return <h1>You do not have access</h1>;
  }

  try {
    const { exp } = jwtDecode(token);

    if (exp < new Date().getTime() / 1000) return false;
  } catch (error) {
    return false;
  }

  return true;
};

const checkPayment = async (path, history) => {
  const token = localStorage.getItem('token');
  if (!token) return { ok: false };
  const { account_id: accountId, account_type: accType } = jwtDecode(token);
  const res = await checkPagePermissions(accountId, accType);

  if (res.data.isActive === false) {
    // const transaction = await createTransaction('supplier');

    history.push({
      pathname: `/supplier/payment`,
      search: `?id=${accountId}`,
      // state: { ...transaction.data },
    });
    return { ok: false };
  }

  return { ok: true };
};

const AuthenticatedRoute = ({ component: Component, history, ...rest }) => {
  const [hasAccess, setHasAccess] = useState();
  const [hasPaid, setHasPaid] = useState();

  useEffect(() => {
    const check = async () => {
      const res = await checkAuth(rest.path, history);

      if (!res) {
        setHasAccess(false);
      } else {
        setHasAccess(true);
      }

      const paid = await checkPayment(rest.path, history);
      setHasPaid(paid);
    };

    check();
  }, [rest.path]);

  if (hasAccess === undefined || hasPaid === undefined) {
    return <Loader />;
  }

  if (!hasPaid.ok && rest.path !== '/supplier/payment') {
    return null;
    // return (
    //   <Route
    //     {...rest}
    //     render={props => (
    //       <Redirect
    //         to={{
    //           pathname: `/supplier/payment?id=${hasPaid.transaction.data.transaction.accountId}`,
    //           state: { from: props.location, ...hasPaid.transaction.data },
    //         }}
    //       />
    //     )}
    //   />
    // );
  }

  if (rest.render) {
    if (hasAccess) {
      return <Route {...rest} render={rest.render} />;
    }

    return (
      <Route
        {...rest}
        render={props => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props =>
        hasAccess ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(AuthenticatedRoute);
