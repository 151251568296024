import React from 'react';
import Container from 'react-bootstrap/Container';

const Home = () => (
  <Container>
    <h1>Home</h1>
  </Container>
);

export default Home;
