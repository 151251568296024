import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getApplicants = async id => {
  const { data } = await getRequest(true, Endpoints.GET_APPLICANTS, {}, { id });
  return camelCaseKeys(data.data);
};

export default getApplicants;
