import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const updateCommunicationPreferences = async (gdprConsentEmail, gdprConsentSms) => {
  const { data } = await postRequest(
    true,
    Endpoints.UPDATE_COMMUNICATION_PREFERENCES,
    { gdpr_consent_email: gdprConsentEmail, gdpr_consent_sms: gdprConsentSms },
    {},
    'PUT',
  );
  return camelCaseKeys(data.data);
};

export default updateCommunicationPreferences;
