import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const postJob = async payload => {
  const { data } = await postRequest(true, Endpoints.POST_JOB, payload);
  return camelCaseKeys(data.data);
};

export default postJob;
