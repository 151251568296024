import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getAllData = async id => {
  const { data } = await getRequest(true, Endpoints.GDPR_ALL_DATA, {}, { id });
  return camelCaseKeys(data.data);
};

export default getAllData;
