import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const resetPasswordRequest = async email => {
  const { data } = await postRequest(false, Endpoints.RESET_PASSWORD_REQUEST, {
    email_address: email,
  });
  return camelCaseKeys(data.data);
};

export default resetPasswordRequest;
