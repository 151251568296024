const AccountTypes = {
  COMPANY: {
    id: 1,
    name: 'Company',
  },
  OPERATIVE: {
    id: 2,
    name: 'Operative',
  },
  SUPPLIER: {
    id: 3,
    name: 'Supplier',
  },
  ADMIN: {
    id: 4,
    name: 'Admin',
  },
  RECRUITER: {
    id: 5,
    name: 'Recruitment Agency',
  },
  ACCOMMODATION: {
    id: 6,
    name: 'Property Owner',
  },
};

export default AccountTypes;
