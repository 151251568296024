import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './PostedJobs.scss';

import Button from '../Reusable/Button';

const PostedJob = ({
  id,
  jobTitle,
  placementType,
  jobStartDate,
  jobEndDate,
  applications,
  jobDescription,
  expired,
  handleJobDelete,
}) => (
  <Col xs="12" md="6" lg="4">
    <div
      className={`posted-jobs__job ${expired === '1' && jobStartDate && jobEndDate && 'expired'}`}
    >
      {expired === '1' && jobStartDate && jobEndDate && (
        <div className="expired">
          <div className="expired__overlay" />
          <div className="expired__content">
            <h2>Expired</h2>
          </div>
        </div>
      )}
      <h4 className="posted-jobs__job__title">{jobTitle}</h4>
      <p className="posted-jobs__job__placement-type">{placementType.name}</p>
      {jobStartDate && jobEndDate && (
        <p className="posted-jobs__job__dates">
          {moment(jobStartDate).format('DD/MM/YYYY')} - {moment(jobEndDate).format('DD/MM/YYYY')}
        </p>
      )}
      <p className="posted-jobs__job__dates">{applications.length} Applicants</p>
      <span
        className="job-card__description"
        dangerouslySetInnerHTML={{ __html: jobDescription }}
      />
      {Boolean(applications.length) && (
        <Link to={`/jobs/${id}/applicants`}>
          <Button className="posted-jobs__job__view-button">View Applicants</Button>
        </Link>
      )}
      <Link to={`/jobs/${id}/edit`}>
        <Button className="posted-jobs__job__view-button">Edit Job</Button>
      </Link>

      <Button className="posted-jobs__job__view-button" onClick={() => handleJobDelete(id)}>
        Delete Job
      </Button>
    </div>
  </Col>
);

PostedJob.propTypes = {
  id: PropTypes.number.isRequired,
  jobTitle: PropTypes.string.isRequired,
  placementType: PropTypes.shape({ name: PropTypes.string }).isRequired,
  jobStartDate: PropTypes.string.isRequired,
  jobEndDate: PropTypes.string.isRequired,
  applications: PropTypes.arrayOf(PropTypes.shape({ createdAt: PropTypes.string.isRequired }))
    .isRequired,
  jobDescription: PropTypes.string.isRequired,
  expired: PropTypes.string.isRequired,
  handleJobDelete: PropTypes.func.isRequired,
};
export default PostedJob;
