import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const adminGetMember = async (id, type) => {
  const { data } = await getRequest(true, Endpoints.ADMIN_GET_MEMBER, { type }, { id });
  return camelCaseKeys(data.data);
};

export default adminGetMember;
