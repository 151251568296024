import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const updateSupplierProfileImage = async (payload, id) => {
  const { data } = await postRequest(
    true,
    Endpoints.UPDATE_SUPPLIER_PROFILE_IMAGE,
    payload,
    { id },
    'PUT',
    null,
  );
  return camelCaseKeys(data.data);
};

export default updateSupplierProfileImage;
