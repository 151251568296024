import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button as BsButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { getRegions, getCertifications, getJobPlacementTypes } from '../../../services';
import { Loader } from '../../Loader';
import Modal from '../../Modal/Modal';
import Button from '../../Reusable/Button';
import useIsMobile from '../../../hooks/useIsMobile';

import './FeedJobs.scss';

const FeedJobFilter = ({
  onRegionChange,
  onCertificationChange,
  onPlacementTypeChange,
  selectedRegions,
  selectedCertifications,
  selectedPlacementTypes,
  setSelectedRegions,
  setSelectedCertifications,
  setSelectedPlacementTypes,
  fetchJobs,
}) => {
  const [certifications, setCertifications] = useState([]);
  const [regions, setRegions] = useState([]);
  const [placementTypes, setPlacementTypes] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showAllRegions, setShowAllRegions] = useState(false);
  const [showAllCertifications, setShowAllCertifications] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchRegions = async () => {
      const res = await getRegions();
      setRegions(res.map(region => ({ value: region.id, label: region.name })));
    };
    const fetchCertifications = async () => {
      const res = await getCertifications();
      setCertifications(res.map(cert => ({ value: cert.id, label: cert.name })));
    };
    const fetchPlacementTypes = async () => {
      const res = await getJobPlacementTypes();
      setPlacementTypes(res.map(type => ({ value: type.id, label: type.name })));
    };

    fetchRegions();
    fetchCertifications();
    fetchPlacementTypes();
  }, []);

  const resetFilters = () => {
    setSelectedRegions([]);
    setSelectedCertifications([]);
    setSelectedPlacementTypes([]);
    fetchJobs();
    setShowFilters(false);
  };

  const applyFilters = () => {
    fetchJobs(selectedRegions, selectedCertifications, selectedPlacementTypes);
  };

  if (!regions.length || !certifications.length || !placementTypes.length) {
    return <Loader />;
  }

  const disabled =
    (!selectedRegions || !selectedRegions.length) &&
    (!selectedCertifications || !selectedCertifications.length) &&
    (!selectedPlacementTypes || !selectedPlacementTypes.length);

  const removeSelectedRegion = label => {
    const newArr = [...selectedRegions].filter(x => x.label !== label);
    setSelectedRegions(newArr);
    fetchJobs(newArr, selectedCertifications, selectedPlacementTypes);
  };
  const removeSelectedCertification = label => {
    const newArr = [...selectedCertifications].filter(x => x.label !== label);
    setSelectedCertifications(newArr);
    fetchJobs(selectedRegions, newArr, selectedPlacementTypes);
  };
  const removeSelectedPlacementType = label => {
    const newArr = [...selectedPlacementTypes].filter(x => x.label !== label);
    setSelectedPlacementTypes(newArr);
    fetchJobs(selectedRegions, selectedCertifications, newArr);
  };

  const RegionsSelect = () => (
    <>
      <Form.Label>Regions</Form.Label>
      <Select
        name="regions"
        id="regions"
        options={regions}
        isMulti
        onChange={onRegionChange}
        value={selectedRegions}
      />
    </>
  );
  const CertificationsSelect = () => (
    <>
      <Form.Label>Certifications</Form.Label>
      <Select
        name="certifications"
        id="certifications"
        options={certifications}
        isMulti
        onChange={onCertificationChange}
        value={selectedCertifications}
      />
    </>
  );
  const PlacementTypesSelect = () => (
    <>
      <Form.Label>Placement Types</Form.Label>
      <Select
        name="placement_type"
        id="placement_type"
        options={placementTypes}
        onChange={onPlacementTypeChange}
        isMulti
        value={selectedPlacementTypes}
      />
    </>
  );

  return (
    <div className="feed__filter">
      {/* lg screen filter */}
      <div className="d-none d-lg-block">
        <Row className="feed__filter__container">
          <Col xs="3">
            <RegionsSelect />
          </Col>
          <Col xs="3">
            <CertificationsSelect />
          </Col>
          <Col xs="3">
            <PlacementTypesSelect />
          </Col>
          <Col className="pl-0 pr-0">
            <Button onClick={applyFilters}>Apply Filter</Button>
          </Col>
          <Col className="pr-0">
            <Button outline onClick={resetFilters} disabled={disabled}>
              Reset
            </Button>
          </Col>
        </Row>
      </div>
      {/* Mobile Filter */}
      <div className="d-lg-none">
        <>
          {selectedRegions && selectedRegions.length ? (
            <div className="tags-container">
              <p>Selected Regions: </p>
              {selectedRegions.slice(0, showAllRegions ? '100000' : '2').map(region => (
                <div className="selected-region-tag" key={region.value}>
                  <div className="tag">{region.label}</div>
                  <div
                    className="close"
                    onClick={() => removeSelectedRegion(region.label)}
                    onKeyPress={e => e.key === 'Enter' && removeSelectedRegion(region.label)}
                    role="button"
                    tabIndex="0"
                  >
                    <i className="fas fa-times" />
                  </div>
                </div>
              ))}
              {selectedRegions.length > 2 && (
                <div
                  className="selected-region-tag"
                  onClick={() => setShowAllRegions(!showAllRegions)}
                  onKeyPress={e => e.key === 'Enter' && setShowAllRegions(!showAllRegions)}
                  role="button"
                  tabIndex="0"
                >
                  <div className="tag">
                    {showAllRegions ? '-' : '+'} {selectedRegions.length - 2}
                  </div>
                </div>
              )}
            </div>
          ) : (
            ''
          )}
          {selectedCertifications && selectedCertifications.length ? (
            <div className="tags-container">
              <p>Selected Certifications: </p>

              {selectedCertifications.slice(0, showAllCertifications ? '100000' : '2').map(cert => (
                <div className="selected-region-tag" key={cert.value}>
                  <div className="tag">{cert.label}</div>
                  <div
                    className="close"
                    onClick={() => removeSelectedCertification(cert.label)}
                    onKeyPress={e => e.key === 'Enter' && removeSelectedCertification(cert.label)}
                    role="button"
                    tabIndex="0"
                  >
                    <i className="fas fa-times" />
                  </div>
                </div>
              ))}
              {selectedCertifications.length > 2 && (
                <div
                  className="selected-region-tag"
                  onClick={() => setShowAllCertifications(!showAllCertifications)}
                  onKeyPress={e =>
                    e.key === 'Enter' && setShowAllCertifications(!showAllCertifications)
                  }
                  role="button"
                  tabIndex="0"
                >
                  <div className="tag">
                    {showAllCertifications ? '-' : '+'} {selectedCertifications.length - 2}
                  </div>
                </div>
              )}
            </div>
          ) : (
            ''
          )}
          {selectedPlacementTypes && selectedPlacementTypes.length ? (
            <div className="tags-container">
              <p>Selected Placement Types: </p>

              {selectedPlacementTypes.map(type => (
                <div className="selected-region-tag" key={type.value}>
                  <div className="tag">{type.label}</div>
                  <div
                    className="close"
                    onClick={() => removeSelectedPlacementType(type.label)}
                    onKeyPress={e => e.key === 'Enter' && removeSelectedPlacementType(type.label)}
                    role="button"
                    tabIndex="0"
                  >
                    <i className="fas fa-times" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
          {(selectedRegions && selectedRegions.length) ||
          (selectedCertifications && selectedCertifications.length) ||
          (selectedPlacementTypes && selectedPlacementTypes.length) ? (
            <BsButton variant="outline-dark" className="clear-all-btn" onClick={resetFilters}>
              Clear All
            </BsButton>
          ) : (
            ''
          )}
        </>

        <BsButton
          variant="outline-dark"
          className="filter-btn"
          onClick={() => setShowFilters(true)}
        >
          Filter
        </BsButton>
        <Modal
          width={!isMobile && '80'}
          title="Filter Jobs"
          show={showFilters}
          handleClose={() => {
            setShowFilters(false);
            setSelectedRegions([]);
            setSelectedCertifications([]);
            setSelectedPlacementTypes([]);
          }}
        >
          <Row>
            <Col xs="12" md="4" className="filter mb-3">
              <RegionsSelect />
            </Col>
            <Col xs="12" md="4" className="filter mb-3">
              <CertificationsSelect />
            </Col>
            <Col xs="12" md="4" className="filter mb-3">
              <PlacementTypesSelect />
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <Button className="filter-button" outline onClick={resetFilters} disabled={disabled}>
                Reset
              </Button>
            </Col>
            <Col xs="6">
              <Button
                className="filter-button"
                onClick={() => {
                  applyFilters();
                  setShowFilters(false);
                }}
              >
                Apply Filter
              </Button>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
};

FeedJobFilter.propTypes = {
  onRegionChange: PropTypes.func.isRequired,
  onCertificationChange: PropTypes.func.isRequired,
  onPlacementTypeChange: PropTypes.func.isRequired,
  selectedRegions: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedCertifications: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedPlacementTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  setSelectedRegions: PropTypes.func.isRequired,
  setSelectedCertifications: PropTypes.func.isRequired,
  setSelectedPlacementTypes: PropTypes.func.isRequired,
  fetchJobs: PropTypes.func.isRequired,
};

export default FeedJobFilter;
