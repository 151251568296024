import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const registerSupplier = async payload => {
  const { data } = await postRequest(false, Endpoints.POST_REGISTER_SUPPLIER, payload);
  return camelCaseKeys(data.data);
};

export default registerSupplier;
