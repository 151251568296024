import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import validateResetPasswordRequest from '../../services/ValidateResetPasswordRequest';
import Loader from '../Loader/Loader';
import ResetPasswordForm from './ResetPasswordForm';
import changePassword from '../../services/ChangePassword';

const ResetPassword = ({ location }) => {
  const [valid, setValid] = useState(null);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const values = queryString.parse(location.search);

    const validate = async () => {
      try {
        await validateResetPasswordRequest(values.hash, values.account_id);
        setValid(true);
      } catch (e) {
        if (e.response.status === 403) {
          toast.error(<>Your reset password request has expired, please request again.</>);
        } else {
          toast.error(<>We cannot complete this request at this time, please try again later.</>);
        }
        setValid(false);
      }
    };

    validate();
  }, [location.search]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const qs = queryString.parse(location.search);
    try {
      await changePassword(qs.account_id, values.password, values.password_confirmation, qs.hash);
      setRedirect('/login');
    } catch (e) {
      toast.error(<>We cannot complete this request at this time, please try again later.</>);
    }
    setSubmitting(false);
  };

  if (valid === null) {
    return <Loader />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <ToastContainer id="error" autoClose={10000} closeOnClick={false} />

      <div className="background">
        <div className="overlay" />
        <div className="content centered">
          <h2 className="background__title">Reset Your Password</h2>
          {valid ? (
            <ResetPasswordForm onSubmit={handleSubmit} />
          ) : (
            <div className="background__inner p-3">
              <p>
                Your reset password request has expired,{' '}
                <Link to="/forgot-password">please request again.</Link>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ResetPassword.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withRouter(ResetPassword);
