import React from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';

import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['clean'],
  ],
};

const TextEditor = ({ onChange, onBlur, value, ...rest }) => (
  <ReactQuill
    {...rest}
    value={value}
    onChange={onChange(rest.name)}
    onBlur={onBlur}
    modules={modules}
  />
);

TextEditor.defaultProps = {
  value: '',
};
TextEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string,
};
export default TextEditor;
