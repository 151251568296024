/* eslint-disable react/prop-types */
import React from 'react';
import { Form, Row, Col, Container } from 'react-bootstrap';
import { Field } from 'formik';

import './PostJobForm.scss';

import DateRange from './DateRange';
import Button from '../Reusable/Button';

const AdditionalDetails = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  back,
  regions,
  isSubmitting,
  inEdit,
}) => (
  <Container>
    <Form.Group>
      <Form.Label className="post-job-form__label">Pay Details</Form.Label>
      <Form.Control
        name="pay_details"
        type="text"
        placeholder="Pay Details"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.pay_details}
        isInvalid={errors.pay_details && touched.pay_details}
        id="pay_details"
      />
      <Form.Control.Feedback type="invalid">{errors.pay_details}</Form.Control.Feedback>
    </Form.Group>
    {values.placement_type && Number(values.placement_type) !== 1 && (
      <Form.Group>
        <Form.Label className="post-job-form__label">Date</Form.Label>
        <Field component={DateRange} />
        {(errors.start_date || errors.end_date) && (
          <div className="invalid-feedback">Pay Details is Required</div>
        )}
      </Form.Group>
    )}
    <Form.Group>
      <Form.Label className="post-job-form__label">Area of Work</Form.Label>
      <Form.Control
        as="select"
        name="region"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.region}
        isInvalid={errors.region && touched.region}
        id="region"
      >
        <option value="" readOnly disabled>
          Please Select a Region
        </option>
        {regions.map(region => (
          <option key={region.id} value={region.id}>
            {region.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{errors.region}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="post-job-form__label">Additional Location Details</Form.Label>
      <Form.Control
        name="additional_location_details"
        type="text"
        placeholder="Location Details"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.additional_location_details}
        isInvalid={errors.additional_location_details && touched.additional_location_details}
        id="pay_details"
      />
      <Form.Control.Feedback type="invalid">
        {errors.additional_location_details}
      </Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Check
        name="accommodation_included"
        type="checkbox"
        onChange={handleChange}
        onBlur={handleBlur}
        checked={values.accommodation_included}
        value={values.accommodation_included}
        isInvalid={errors.accommodation_included && touched.accommodation_included}
        id="accommodation_included"
        label="Accommodation Included?"
      />
      <Form.Control.Feedback type="invalid">{errors.accommodation_included}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Check
        name="loyalty_bonus_optin"
        type="checkbox"
        onChange={handleChange}
        onBlur={handleBlur}
        checked={values.loyalty_bonus_optin}
        value={values.loyalty_bonus_optin}
        isInvalid={errors.loyalty_bonus_optin && touched.loyalty_bonus_optin}
        id="loyalty_bonus_optin"
        label="Opt in to paying a £2 per day loyalty bonus, Upon ticking this box you agree to pay the candidate upon completion of the works the amount owed to them for them for seeing the job through to completion without unauthorised absence."
      />
      <Form.Control.Feedback type="invalid">{errors.loyalty_bonus_optin}</Form.Control.Feedback>
    </Form.Group>
    <Row>
      <Col>
        <Form.Group>
          <Button className="float-right mt-3 post-job-form--back" type="button" onClick={back}>
            Back
          </Button>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Button
            className="float-right mt-3 post-job-form--submit"
            type="submit"
            disabled={isSubmitting || Object.keys(errors).length}
          >
            {inEdit ? 'Save' : 'Submit'}
          </Button>
        </Form.Group>
      </Col>
    </Row>
  </Container>
);

export default AdditionalDetails;
