import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const validateResetPasswordRequest = async (token, accountId) => {
  const { data } = await postRequest(false, Endpoints.VALIDATE_RESET_PASSWORD_REQUEST, {
    token,
    account_id: accountId,
  });
  return camelCaseKeys(data.data);
};

export default validateResetPasswordRequest;
