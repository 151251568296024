import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as PaginationBs } from 'react-bootstrap';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const items = [];
  const active = currentPage;
  let startPage = 1;
  if (currentPage < 4) {
    startPage = 1;
    // between end and end - 4
  } else if (currentPage >= totalPages - 4) {
    startPage = totalPages - 6 > 0 ? totalPages - 6 : 1;
  } else {
    startPage = currentPage - 3 > 0 ? currentPage - 3 : 0;
  }
  const endPage = startPage + 6 <= totalPages ? startPage + 6 : totalPages;

  for (let number = startPage; number <= endPage; number++) {
    items.push(
      <PaginationBs.Item
        key={number}
        active={number === active}
        onClick={() => onPageChange(number)}
      >
        {number}
      </PaginationBs.Item>,
    );
  }

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="text-center pagination">
      <PaginationBs style={{ margin: '0 auto' }}>
        <PaginationBs.First onClick={() => onPageChange(1)} disabled={currentPage === 1} />
        <PaginationBs.Prev
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        />
        {items}
        <PaginationBs.Next
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <PaginationBs.Last
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        />
      </PaginationBs>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
