import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getAccount = async () => {
  const { data } = await getRequest(true, Endpoints.GET_ACCOUNT);
  return camelCaseKeys(data.data);
};

export default getAccount;
