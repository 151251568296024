import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const removeCertificationFiles = async id => {
  const { data } = await postRequest(
    true,
    Endpoints.DELETE_OPERATIVE_PROFILE_CERTIFICATION_FILES,
    {},
    { id },
    'PUT',
    null,
  );
  return data.data;
};

export default removeCertificationFiles;
