import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const deleteAccommodation = async id => {
  const { data } = await postRequest(true, Endpoints.DELETE_ACCOMMODATION, {}, { id }, 'DELETE');

  return camelCaseKeys(data);
};

export default deleteAccommodation;
