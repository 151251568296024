import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { Button } from '../Reusable';

const LoginForm = ({ onSubmit, accountTypes, admin, setError }) => {
  const validationSchema = {
    email_address: yup
      .string()
      .email('Email Address is not valid')
      .nullable()
      .required('Email Address is Required'),
    password: yup
      .string()
      .nullable()
      .required('Password is Required'),
  };

  const initialValues = {
    email_address: '',
    password: '',
    account_type: 1,
  };

  if (admin) {
    initialValues.account_type = yup
      .string()
      .nullable()
      .required('Account Type is Required');
    initialValues.account_type = 1;
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape(validationSchema)}
      onSubmit={onSubmit}
      render={({ handleSubmit, handleChange, handleBlur, values, errors }) => (
        <Form onSubmit={handleSubmit} className="login-form">
          {!admin && (
            <Form.Group>
              <Form.Label className="login-form__label">Account Type</Form.Label>
              <Form.Control
                as="select"
                name="account_type"
                onChange={e => {
                  handleChange(e);
                  setError(null);
                }}
                onBlur={handleBlur}
                value={values.account_type}
                isInvalid={errors.account_type}
                id="account_type"
              >
                {accountTypes.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.account_type}</Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label className="login-form__label">Email Address</Form.Label>
            <Form.Control
              name="email_address"
              type="text"
              placeholder="Email Address"
              onChange={e => {
                handleChange(e);
                setError(null);
              }}
              onBlur={handleBlur}
              value={values.email_address}
              isInvalid={errors.email_address}
              id="email_address"
            />
            <Form.Control.Feedback type="invalid">{errors.email_address}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="login-form__label">Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
              onChange={e => {
                handleChange(e);
                setError(null);
              }}
              onBlur={handleBlur}
              value={values.password}
              isInvalid={errors.password}
              id="password"
              autoComplete="current-password"
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          <Button
            className="mt-3"
            type="submit"
            // disabled={isSubmitting || Object.keys(errors).length}
          >
            Login
          </Button>
          <small>
            <Link to="forgot-password">Forgotten your password?</Link>
          </small>
        </Form>
      )}
    />
  );
};

LoginForm.defaultProps = {
  admin: false,
};
LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  accountTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  admin: PropTypes.bool,
};

export default LoginForm;
