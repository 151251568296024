import React, { useState, useEffect, useCallback } from 'react';
import { Form, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';

import Button from '../../Reusable/Button';
import createSuppliesFeedPost from '../../../services/CreateSuppliesFeedPost';
import { Loader } from '../../Loader';
import getRegions from '../../../services/Regions';
import AccountTypes from '../../../constants/accountTypes';
import Modal from '../../Modal/Modal';

const SuppliesFeedCreate = ({ fetchPosts }) => {
  const [value, setValue] = useState();
  const [title, setTitle] = useState();
  const [region, setRegion] = useState('');
  const [regions, setRegions] = useState([]);
  const [files, setFiles] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const accountType = Object.keys(AccountTypes).find(
    type => AccountTypes[type].id === Number(localStorage.getItem('account_type')),
  );

  useEffect(() => {
    const fetchRegions = async () => {
      const res = await getRegions();
      setRegions(res);
    };

    fetchRegions();
  }, []);

  const handleSubmit = async () => {
    setValue('');
    setTitle('');
    setRegion('');
    setFiles(null);
    setIsSubmitting(true);

    const formData = new FormData();
    if (files && files.length) {
      files.forEach(file => {
        formData.append('image', file);
      });
    }

    formData.append('title', title);
    formData.append('description', value);
    formData.append('common_region_id', region);

    try {
      await createSuppliesFeedPost(formData);
    } catch (e) {
      if (e.response.status === 429) {
        setError('You can only post to the feed twice per day, please try again tomorrow');
      }
      return;
    }
    await fetchPosts();
    setIsSubmitting(false);
  };

  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length) {
      setFiles(
        acceptedFiles.map(f =>
          Object.assign(f, {
            preview: URL.createObjectURL(f),
          }),
        ),
      );

      acceptedFiles.forEach(file => toast.success(`${file.path} was successfully uploaded.`));
    }
  }, []);

  if (!regions.length || !accountType) {
    return <Loader />;
  }

  return (
    <>
      <Form.Group className="mt-4">
        <Form.Label className="mt-4">Post to the Feed</Form.Label>
        <Form.Control
          as="textarea"
          rows="3"
          onClick={() => setShowModal(true)}
          placeholder="Post..."
        />
      </Form.Group>
      <Modal
        width="70"
        show={showModal}
        handleClose={() => setShowModal(false)}
        title="Post to the Feed"
      >
        {error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <div className="supplies_feed__create">
            <Form.Group className="mb-1">
              <Form.Control
                onChange={e => setTitle(e.target.value)}
                value={title}
                placeholder="Title..."
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Control
                as="textarea"
                onChange={e => setValue(e.target.value)}
                rows="2"
                value={value}
                style={{ resize: 'none' }}
                placeholder="Post..."
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Control
                as="select"
                name="region"
                onChange={e => setRegion(e.target.value)}
                value={region}
                id="region"
              >
                <option value="" readOnly disabled>
                  Where?
                </option>
                {regions.map(r => (
                  <option key={r.id} value={r.id}>
                    {r.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {accountType === 'SUPPLIER' && (
              <Dropzone onDrop={onDrop} accept={['image/jpg', 'image/png', 'image/jpeg']}>
                {({ getInputProps, getRootProps }) => (
                  <div {...getRootProps()} className="supplies_feed__dropzone">
                    <input {...getInputProps()} />
                    {files ? (
                      <>
                        <i className="fas fa-file-invoice" />
                        {files.map(file => (
                          <p>{file.path}</p>
                        ))}
                      </>
                    ) : (
                      <p>Drop file(s) here, or click to upload a file</p>
                    )}
                  </div>
                )}
              </Dropzone>
            )}
            <Button
              onClick={handleSubmit}
              disabled={isSubmitting || (!value || !title || !region)}
              className="p-1 mb-4"
            >
              Post
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

SuppliesFeedCreate.propTypes = {
  fetchPosts: PropTypes.func.isRequired,
};
export default SuppliesFeedCreate;
