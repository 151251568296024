import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import io from 'socket.io-client';
import { toast, ToastContainer } from 'react-toastify';
import camelCaseKeys from 'camelcase-keys-recursive';

import './App.scss';

import Router from './Router';
import { Header } from './components/Header';
import Loader from './components/Loader/Loader';
import Pages from './constants/pages';
import { checkPagePermissions, logout } from './services';
import Footer from './components/Footer/Footer';
import CookieConsent from './components/CookieConsent/CookieConsent';
import AccountTypes from './constants/accountTypes';
import Forbidden from './components/Forbidden/Forbidden';

const socket = io.connect(process.env.REACT_APP_API_URL);

function App(props) {
  console.log('DO APP WORKING');
  const [authenticated, setAuthenticated] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [forbidden, setForbidden] = useState(false);
  const { location, history } = props;
  const [cookies, setCookie] = useCookies(['cookie-consent']);
  const { REACT_APP_COMING_SOON: comingSoon } = process.env;

  useEffect(() => {
    if (
      localStorage.getItem('account_type') &&
      Number(localStorage.getItem('account_type')) === AccountTypes.ADMIN.id &&
      location.pathname === '/'
    ) {
      history.push('/admin/home');
    }

    const unAuthedPages = Object.keys(Pages)
      .map(page => (!Pages[page].authenticated ? Pages[page].path : null))
      .filter(Boolean);

    if (
      (comingSoon === true &&
        !location.pathname.includes('/admin') &&
        !unAuthedPages.includes(location.pathname)) ||
      (location.pathname === '/' && !localStorage.getItem('account_id'))
    ) {
      setAuthenticated(true);
      // history.push('/coming-soon');
      return;
    }

    const accountType = localStorage.getItem('account_type');
    const accountId = localStorage.getItem('account_id');

    /**
     * Make sure the user hasn't changed their account type
     * in local storage, and if they have, log them out.
     *
     * Also checks token is still valid, 401 if not
     */
    const checkPermissions = async () => {
      try {
        await checkPagePermissions(accountId, accountType);
        setAuthenticated(true);
      } catch (e) {
        setAuthenticated(false);
        if (e.response.status === 401) {
          // Token invalid
          localStorage.removeItem('token');
          localStorage.removeItem('account_id');
          localStorage.removeItem('account_type');
          history.push('/login');
          return;
        }

        if (e.response.status === 403) {
          // Permissions issue
          await logout();
          localStorage.removeItem('token');
          localStorage.removeItem('account_id');
          localStorage.removeItem('account_type');
          history.push('/login');
        }
      }
    };

    const route = Object.keys(Pages).find(r => {
      if (Pages[r].path && typeof Pages[r].path === 'string') {
        return Pages[r].path === location.pathname;
      }

      const reg = Pages[r].path();
      return Boolean(location.pathname.match(reg));
    });

    // Not logged in
    if (
      !localStorage.getItem('token') &&
      window.location.pathname !== '/admin/login' &&
      !unAuthedPages.includes(location.pathname)
    ) {
      history.push('/login');
      setAuthenticated(false);
      return;
    }

    // Route they're trying to access doesn't exist = 404
    if (!Pages[route]) {
      setAuthenticated(true);
      return;
    }
    console.log(route);
    if (Pages[route].authenticated && !Pages[route].hasAccess[accountType.toString()]) {
      setForbidden(true);
      // history.push('/forbidden');
      setAuthenticated(false);
      return;
    }

    if (localStorage.getItem('token') && Pages[route].authenticated) {
      checkPermissions();
      return;
    }

    setAuthenticated(true);
    setForbidden(false);
  }, [location.pathname, history, comingSoon]);

  useEffect(() => {
    socket.on(`new_message_account_${localStorage.getItem('account_id')}`, data => {
      const message = camelCaseKeys(data.message);
      toast.info(
        <>
          New message from {message.sender.firstName} {message.sender.lastName}:{' '}
          {message.message.substring(0, 45)}
          <br />
          <Link to={`/conversation/${message.conversationId}`}>View Message</Link>
        </>,
      );
    });

    return () => socket.close();
  }, []);

  if (redirect) {
    return (
      <Redirect to={{ pathname: redirect.path, state: redirect.state, search: redirect.search }} />
    );
  }

  if (forbidden) {
    return (
      <>
        <div className="main">
          <Header />
          <Forbidden />
        </div>
      </>
    );
  }

  return (
    <>
      <ToastContainer id="info" autoClose={10000} closeOnClick={false} />

      <div
        className="main"
        style={{
          paddingBottom:
            location.pathname.match(/\/conversation\/(\d+)/) ||
            comingSoon === 'true' ||
            location.pathname.includes('admin')
              ? '0'
              : '',
          paddingTop: comingSoon === 'true' || location.pathname.includes('admin') ? '0' : '',
        }}
      >
        {comingSoon !== 'true' && !location.pathname.includes('admin') && <Header />}
        {authenticated === null && !forbidden ? (
          <Loader />
        ) : (
          <Router authenticated={authenticated} />
        )}
        {forbidden && <Forbidden />}
        {comingSoon !== 'true' &&
          !location.pathname.includes('admin') &&
          !location.pathname.match(/\/conversation\/(\d+)/) && <Footer />}
        {!cookies['cookie-consent'] && <CookieConsent setCookie={setCookie} />}
      </div>
    </>
  );
}

App.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(App);
