import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavItem, NavDropdown } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Button } from '../Reusable';
import {
  clientNavigation,
  operativeNavigation,
  globalNavigation,
  landingPageNavigation,
  supplierNavigation,
  adminNavigation,
  accommodationNavigation,
} from '../../helpers/navigation';
import { logout } from '../../services';
import { AccountTypes } from '../../constants';

import logo from '../../images/logo/Direct-Labour-Exchange-Main.png';
import logomark from '../../images/logomark.png';
import './Header.scss';

const isLoggedIn = () => {
  if (localStorage.getItem('token')) {
    return true;
  }

  return false;
};

export const handleLogoutAccount = async (e, history) => {
  e.preventDefault();
  await logout();

  localStorage.removeItem('token');
  localStorage.removeItem('account_id');
  localStorage.removeItem('account_type');

  history.push('/login');
};

const renderNavLinks = (accountType, pathname, setExpanded) => {
  if (!isLoggedIn() && (pathname === '/about' || pathname === '/')) {
    return landingPageNavigation.map(nav => (
      <NavItem key={nav.path}>
        <HashLink
          to={nav.path}
          className="nav-link"
          id={nav.id || ''}
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          onClick={() => setExpanded(false)}
        >
          {nav.component || nav.name}
        </HashLink>
      </NavItem>
    ));
  }

  switch (parseInt(accountType, 10)) {
    case AccountTypes.COMPANY.id:
    case AccountTypes.RECRUITER.id:
      return (
        <>
          {clientNavigation.map(nav => (
            <NavItem key={nav.path}>
              <Link to={nav.path} className="nav-link">
                {nav.component || nav.name}
              </Link>
            </NavItem>
          ))}
          {globalNavigation.map(nav => (
            <NavItem key={nav.path}>
              <Link to={nav.path} className="nav-link">
                {nav.component || nav.name}
              </Link>
            </NavItem>
          ))}
        </>
      );
    case AccountTypes.OPERATIVE.id:
      return (
        <>
          {operativeNavigation(localStorage.getItem('account_id')).map(nav => {
            if (nav.type && nav.type === 'dropdown') {
              return (
                <NavDropdown title={nav.name} id={nav.id || ''} key={nav.name}>
                  {nav.items.map(item => (
                    <NavDropdown.Item
                      key={item.name}
                      as={() => (
                        <Link
                          to={item.path}
                          className="nav-link"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          {item.name}
                        </Link>
                      )}
                    />
                  ))}
                </NavDropdown>
              );
            }
            return (
              <NavItem key={nav.path}>
                <Link
                  to={nav.path}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  {nav.component || nav.name}
                </Link>
              </NavItem>
            );
          })}
          {globalNavigation.map(nav => (
            <NavItem key={nav.path}>
              <Link to={nav.path} className="nav-link">
                {nav.component || nav.name}
              </Link>
            </NavItem>
          ))}
        </>
      );
    case AccountTypes.SUPPLIER.id:
      return (
        <>
          {supplierNavigation(localStorage.getItem('account_id')).map(nav => {
            if (nav.type && nav.type === 'dropdown') {
              return (
                <NavDropdown title={nav.name} id={nav.id || ''} key={nav.name}>
                  {nav.items.map(item => (
                    <NavDropdown.Item
                      key={item.name}
                      as={() => (
                        <Link
                          to={item.path}
                          className="nav-link"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          {item.name}
                        </Link>
                      )}
                    />
                  ))}
                </NavDropdown>
              );
            }
            return (
              <NavItem key={nav.path}>
                <Link
                  to={nav.path}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  {nav.component || nav.name}
                </Link>
              </NavItem>
            );
          })}
          {globalNavigation.map(nav => (
            <NavItem key={nav.path}>
              <Link to={nav.path} className="nav-link">
                {nav.component || nav.name}
              </Link>
            </NavItem>
          ))}
        </>
      );
    case AccountTypes.ADMIN.id:
      return (
        <>
          {adminNavigation.map(nav => {
            if (nav.type && nav.type === 'dropdown') {
              return (
                <NavDropdown title={nav.name} id={nav.id || ''} key={nav.name}>
                  {nav.items.map(item => (
                    <NavDropdown.Item
                      key={item.name}
                      as={() => (
                        <Link
                          to={item.path}
                          className="nav-link"
                          data-toggle="collapse"
                          data-target=".navbar-collapse.show"
                        >
                          {item.name}
                        </Link>
                      )}
                    />
                  ))}
                </NavDropdown>
              );
            }
            return (
              <NavItem key={nav.path}>
                <Link
                  to={nav.path}
                  className="nav-link"
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                >
                  {nav.component || nav.name}
                </Link>
              </NavItem>
            );
          })}
        </>
      );
    case AccountTypes.ACCOMMODATION.id:
      return (
        <>
          {accommodationNavigation.map(nav => (
            <NavItem key={nav.path}>
              <Link to={nav.path} className="nav-link">
                {nav.component || nav.name}
              </Link>
            </NavItem>
          ))}
        </>
      );
    default:
      return null;
  }
};

const Header = props => {
  const { location } = props;
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  return (
    <Navbar
      expand="lg"
      fixed="top"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      className="navbar"
    >
      <Navbar.Brand
        as={() => (
          <Link to="/" className="nav-link navbar-brand">
            <img
              src={logo}
              alt="Direct Labour Exchange Logo"
              className="img-responsive d-none d-lg-block"
            />
            <img
              src={logomark}
              alt="Direct Labour Exchange Logo"
              className="img-responsive d-lg-none"
            />
          </Link>
        )}
      />
      <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setExpanded(!expanded)} />
      <Navbar.Collapse id="navbar-nav">
        <Nav className="ml-auto">
          {location.pathname !== '/supplier/payment' &&
            renderNavLinks(localStorage.getItem('account_type'), location.pathname, setExpanded)}
          {isLoggedIn() ? (
            <Link to="/" className="nav-link" onClick={e => handleLogoutAccount(e, props.history)}>
              Logout
            </Link>
          ) : (
            <>
              <Button className="nav-register-btn">
                <Link
                  className="nav-link"
                  to="/signup"
                  id="nav-register"
                  onClick={() => setExpanded(false)}
                >
                  Sign Up
                </Link>
              </Button>

              <Button outline className="nav-login-btn">
                <Link
                  className="nav-link"
                  to="/login"
                  id="nav-login"
                  onClick={() => setExpanded(false)}
                >
                  Log in
                </Link>
              </Button>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

Header.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(Header);
