import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import './PostedJobs.scss';

import { getPostedJobs } from '../../services';
import Loader from '../Loader/Loader';
import PostedJob from './PostedJob';
import Pagination from '../Reusable/Pagination';
import deleteJob from '../../services/DeleteJob';

const PostedJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [loading, setLoading] = useState();

  const getJobs = async (p = 1) => {
    setLoading(true);
    const data = await getPostedJobs(p);
    setJobs(data.data);
    setTotalPages(data.meta.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    getJobs();
  }, []);

  const onPageChange = async p => {
    setPage(p);
    await getJobs(p);
  };

  const handleJobDelete = async id => {
    // eslint-disable-next-line
    if (window.confirm('Are you sure you want to delete this job?')) {
      await deleteJob(id);
      await getJobs();
    }
  };

  return (
    <div className="background posted-jobs">
      <div className="overlay" />
      <h2 className="background__title">Your Jobs</h2>
      <div className="background__inner">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Row>
              {jobs.length ? (
                jobs.map(job => <PostedJob {...job} handleJobDelete={handleJobDelete} />)
              ) : (
                <h3>No results found</h3>
              )}
            </Row>
            <Pagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </>
        )}
      </div>
    </div>
  );
};

export default PostedJobs;
