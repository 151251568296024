import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import useIsMobile from '../../hooks/useIsMobile';

const DateRange = ({ form: { setFieldValue, values } }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const isMobile = useIsMobile();

  return (
    <div className="date-range">
      {isMobile ? (
        <DateRangePicker
          startDate={values.start_date}
          startDateId="tata-start-date"
          endDate={values.end_date}
          endDateId="tata-end-date"
          onDatesChange={({ startDate, endDate }) => {
            setFieldValue('start_date', startDate);
            setFieldValue('end_date', endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={_focusedInput => setFocusedInput(_focusedInput)}
          readOnly
          verticalHeight={370}
          orientation="vertical"
          numberOfMonths={1}
          style={{ left: '20px' }}
        />
      ) : (
        <DateRangePicker
          startDate={values.start_date}
          startDateId="tata-start-date"
          endDate={values.end_date}
          endDateId="tata-end-date"
          onDatesChange={({ startDate, endDate }) => {
            setFieldValue('start_date', startDate);
            setFieldValue('end_date', endDate);
          }}
          focusedInput={focusedInput}
          onFocusChange={_focusedInput => setFocusedInput(_focusedInput)}
        />
      )}
    </div>
  );
};

DateRange.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DateRange;
