import React from 'react';
import PropTypes from 'prop-types';
import { Button as Btn, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Button } from '../Reusable';

import './LandingPage.scss';
import useIsMobile from '../../hooks/useIsMobile';

const LandingPage = () => {
  const isMobile = useIsMobile();

  const Divider = () =>
    isMobile ? (
      <Col xs="12" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div className="landing__section-divider" />
      </Col>
    ) : null;

  const Anchor = ({ id, offset }) => (
    <div style={{ position: 'relative', visibility: 'hidden' }}>
      <div id={id} style={{ position: 'absolute', top: offset, left: 0 }} />
    </div>
  );
  Anchor.propTypes = { id: PropTypes.string.isRequired, offset: PropTypes.string.isRequired };

  return (
    <Container fluid>
      <Row className="landing">
        <Anchor id="about" />
        <Col className="landing__section landing__section--1" xs="12">
          <div className="landing__section--1__overlay" />
          <div className="landing__section--1__content">
            <h1>Direct Labour Exchange is a &#39;trade-only&#39; platform</h1>
            <p>
              Construction Companies, Labour Operatives & Suppliers in the Construction Industry
            </p>
            <div className="text-center w-100 float-left">
              <Link to="/signup">
                <Button>Sign up for free</Button>
              </Link>
            </div>
            <p>
              Already have an account? <Link to="/login">Log in</Link>
            </p>
            <div className="text-center btn-cont">
              <HashLink to="/#why">
                <Btn variant="outline-light">
                  <i className="fas fa-angle-down" />
                </Btn>
                <small>Who we are</small>
              </HashLink>
            </div>
          </div>
        </Col>

        <Divider />

        <Col className="landing__section landing__section--2" xs="12">
          <Anchor id="why" offset={isMobile ? '-130px' : '-160px'} />
          <div className="landing__section--2__overlay" />
          <div className="landing__section--2__content">
            <h2>Why Direct Labour Exchange?</h2>
            <p>
              We help our members businesses to improve their efficiency, gain new clients and forge
              long-lasting business relationships.
            </p>
            <p>
              By using Direct Labour Exchange, hiring labour becomes cost effective for clients and
              a simpler way of obtaining work for operatives.
            </p>
          </div>
        </Col>

        <Divider />

        <Col className="landing__section landing__section--3" xs="12" lg="6">
          <Anchor id="operative" offset="-110px" />
          <div className="landing__section--3__overlay" />
          <div className="landing__section--3__content">
            <h2>Labour Operatives</h2>
            <div className="landing__section--3__content--container">
              <p>Do you work in construction?</p>
              <p>Are you self-employed and looking for agency work?</p>
              <p>
                Gain access to jobs across the UK instantly, as they are posted through your feed.
              </p>
            </div>
            <Button>
              <Link to="/register/operative">Register as an Operative</Link>
            </Button>
          </div>
        </Col>

        <Divider />

        <Col className="landing__section landing__section--4" xs="12" lg="6">
          <Anchor id="company" offset="-110px" />
          <div className="landing__section--4__overlay" />
          <div className="landing__section--4__content">
            <h2>Construction Company</h2>
            <div className="landing__section--4__content--container">
              <p>
                You have come to the right place. Direct Labour Exchange have thousands of
                registered operatives looking for work daily. Post direct or let us find the labour
                for you.
              </p>
              <p>
                As a company you will also have access to hundreds of suppliers to the construction
                industry throughout the UK, ranging from skip companies, grab hire, workwear and
                more.
              </p>
              <p>
                Simply post a request and let the supplier contact you with quotes, saving you both
                time and money.
              </p>
            </div>
            <Button>
              <Link to="/register/company">Register as a Company</Link>
            </Button>
          </div>
        </Col>

        <Divider />

        <Col className="landing__section landing__section--5" xs="12" lg="6">
          <Anchor id="supplier" offset="-110px" />
          <div className="landing__section--5__overlay" />
          <div className="landing__section--5__content">
            <h2>Supplier</h2>
            <div className="landing__section--5__content--container">
              <p>Are you a Supplier to the Construction industry?</p>
              <p>Direct Labour Exchange is the ideal place to find potential business.</p>
              <p>
                Advertise your company to 1000&apos;s of potential customers on our supplier page.
                Post your services to the suppliers feed up to twice per day to let others see what
                you have to offer and contact you if interested.
              </p>
              <p>
                When posting to the feed over 10&#44;000 self employed operatives and 100&apos;s of
                uk companies can see what services you have to offer.
              </p>
              <p>
                All suppliers will also be shared and recommended throughout our social media
                channels. Add a special discount to all direct Labour exchange members to gain more
                leads.
              </p>
            </div>
            <Button>
              <Link to="/register/supplier">
                Register now with 75&#37; off for only &pound;49.99
              </Link>
            </Button>
          </div>
        </Col>
        <Col className="landing__section landing__section--6" xs="12" lg="6">
          <Anchor id="recruitment" offset="-110px" />
          <div className="landing__section--6__overlay" />
          <div className="landing__section--6__content">
            <h2>Recruitment Agency</h2>
            <div className="landing__section--6__content--container">
              <p>
                Are you a Recruitment company looking for temporary or permanent staff in the
                construction industry?
              </p>
              <p>
                Gain access to 1000s of self employed operatives profiles and CVs by simply
                registering below.
              </p>
            </div>
            <Button>
              <Link to="/register/recruiter">Register as a Recruitment Agency</Link>
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="landing__section landing__section--7" xs="12" lg="12">
          <Anchor id="accommodation" offset="-110px" />
          <div className="landing__section--7__overlay" />
          <div className="landing__section--7__content">
            <h2>Property Owner</h2>
            <div className="landing__section--7__content--container">
              <p>Do you own rental accommodation?</p>

              <p>
                Would you like to advertise your accommodation in front of 1000s of self-employed
                agency workers and companies looking for accommodation all over the UK week in week
                out all year round for a one-off fee?
              </p>

              <p>
                When advertising with The Direct Labour Exchange there is no on/off season the
                construction industry operates 12 months of the year allowing you to gain income
                through the quieter months.
              </p>

              <p>
                Whether it&#39;s a B&B, Serviced Apartment, Static Caravan, Holiday Home, Guest
                House, Pub or AirB&B property, our platform is a great way to advertise.
              </p>

              <p>
                We do not charge fees or commission for any rentals we just simply take a one-off
                fee for 12 months of advertising on the site.
              </p>
              <p>
                We also promote your property through our growing social media platforms and work
                with various agencies and companies across the country to help find accommodation,
                be it an apartment/cottage for a workforce for 4 Months or an AirB&B for two
                operatives for two nights.
              </p>
              <p>
                12 Month advertising is available for only &pound;49.99 and registration takes less
                than 5 minutes, so what are you waiting for?
              </p>
            </div>
            <Button>
              <Link to="/register/accommodation">Register as a Property Owner</Link>
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPage;
