import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../../constants';
import { postRequest } from '../../helpers/requestHelpers';

const checkFeedbackTokenValid = async token => {
  const { data } = await postRequest(true, Endpoints.CHECK_FEEDBACK_TOKEN_VALID, { token });
  return camelCaseKeys(data.data);
};

export default checkFeedbackTokenValid;
