import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

const Error = ({ message, code = null, title = null }) => (
  <Alert variant="danger">
    {title && <h4>{title}</h4>}
    <p className="mb-1">{message}</p>
    {code && (
      <>
        <small>error code: {code}</small>
      </>
    )}
  </Alert>
);

Error.defaultProps = {
  code: null,
  title: null,
};

Error.propTypes = {
  message: PropTypes.string.isRequired,
  code: PropTypes.number,
  title: PropTypes.string,
};

export default Error;
