import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getAccommodation = async (page, regions = [], cities = [], keyword = null, admin) => {
  const { data } = await getRequest(true, Endpoints.GET_ACCOMMODATIONS, {
    page,
    regions,
    cities,
    keyword,
    admin,
  });
  return camelCaseKeys(data);
};

export default getAccommodation;
