/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Container } from 'react-bootstrap';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import './PostJobForm.scss';

import TextEditor from '../Reusable/Input/TextEditor';
import Button from '../Reusable/Button';

const BasicInfo = ({
  placementTypes,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  validateForm,
  basicInfoErrors,
  next,
  setFieldTouched,
  setFieldValue,
  certOptions,
  occupationOptions,
}) => {
  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    if (!errors.title && !errors.description && !errors.placement_type && !errors.certification) {
      setHasErrors(false);
    }
  }, [values, errors]);

  return (
    <Container>
      <Form.Group>
        <Form.Label className="post-job-form__label">Job Title</Form.Label>
        <Form.Control
          name="title"
          type="text"
          placeholder="Title"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.title}
          isInvalid={errors.title && (touched.title || hasErrors)}
          id="title"
        />
        <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group>
        <Form.Label className="post-job-form__label">Job Description</Form.Label>
        <TextEditor
          id="description"
          name="description"
          onChange={handleChange}
          onBlur={e => {
            handleBlur(e);
            setFieldTouched('description');
          }}
          value={values.description}
        />
        {errors.description && (touched.description || hasErrors) && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {errors.description}
          </div>
        )}
      </Form.Group>
      <Form.Group>
        <Form.Label className="post-job-form__label">Placement Type</Form.Label>
        <Form.Control
          as="select"
          name="placement_type"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.placement_type}
          isInvalid={errors.placement_type && (touched.placement_type || hasErrors)}
          id="placement_type"
        >
          <option value="" readOnly disabled>
            Please Select a Placement Type
          </option>
          {placementTypes.map(pt => (
            <option key={pt.id} value={pt.id}>
              {pt.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{errors.placement_type}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label className="post-job-form__label">Certification(s) Required</Form.Label>
        <CreatableSelect
          name="certification"
          onChange={option => setFieldValue('certification', option)}
          onBlur={() => setFieldTouched('certification', true)}
          value={values.certification}
          id="certification"
          options={certOptions}
          isMulti
          touched={touched.certification}
          isInvalid={errors.certification && touched.certification}
        />
        <small>
          If your certification does not appear in the list, please type it in to create a new one
        </small>
        {errors.certification && touched.certification && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {errors.certification}
          </div>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Label className="post-job-form__label">Occupation(s) Required</Form.Label>
        <Select
          name="occupations"
          onChange={option => setFieldValue('occupations', option)}
          onBlur={() => setFieldTouched('occupations', true)}
          value={values.occupations}
          id="occupations"
          options={occupationOptions}
          isMulti
          touched={touched.occupations}
          isInvalid={errors.occupations && touched.occupations}
        />
        {errors.occupations && touched.occupations && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {errors.occupations}
          </div>
        )}
      </Form.Group>

      <Form.Group>
        <Button
          variant="rounded"
          className="float-right mt-3 post-job-form--next"
          type="button"
          disabled={
            (errors.title || errors.description || errors.certification || errors.placement_type) &&
            hasErrors
          }
          onClick={async () => {
            await validateForm();
            const x = await next('dateAndPay', 'forward', basicInfoErrors, validateForm);
            if (!x) {
              setHasErrors(true);
            }
          }}
        >
          Next
        </Button>
      </Form.Group>
    </Container>
  );
};

export default BasicInfo;
