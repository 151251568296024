import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import { Loader } from './components';
import NotFound from './components/NotFound/NotFound';
import FeedPost from './components/Admin/FeedPost';

const ComingSoon = lazy(() => import('./components/ComingSoon/ComingSoon'));
const Login = lazy(() => import('./components/Login/Login'));
const RegisterClient = lazy(() => import('./components/Register/Client/FormContainer'));
const RegisterOperative = lazy(() => import('./components/Register/Operative/FormContainer'));
const RegisterSupplier = lazy(() => import('./components/Register/Supplier/FormContainer'));
const RegisterRecruiter = lazy(() => import('./components/Register/Recruiter/FormContainer'));
const RegisterAccommodation = lazy(() =>
  import('./components/Register/Accommodation/FormContainer'),
);
const CommonPage = lazy(() => import('./components/CommonPage/CommonPage'));
const LandingPage = lazy(() => import('./components/LandingPage/LandingPage'));
const PostJob = lazy(() => import('./components/PostJob/PostJob'));
const PostedJobs = lazy(() => import('./components/PostedJobs/PostedJobs'));
const AdminHome = lazy(() => import('./components/Admin/Home'));
const MembersList = lazy(() => import('./components/Admin/MembersList'));
const AdminSuppliersList = lazy(() => import('./components/Admin/SuppliersList'));

const Member = lazy(() => import('./components/Admin/Member'));
const Feed = lazy(() => import('./components/Feed/Feed'));
const IndividualJob = lazy(() => import('./components/IndividualJob/IndividualJob'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword/ForgotPassword'));
const Signup = lazy(() => import('./components/Signup/Signup'));
const RootComponent = lazy(() => import('./components/Root'));
const ApplyJob = lazy(() => import('./components/ApplyJob/ApplyJob'));
const AppliedJobs = lazy(() => import('./components/AppliedJobs/AppliedJobs'));
const MatchedJobs = lazy(() => import('./components/MatchedJobs/MatchedJobs'));
const Settings = lazy(() => import('./components/Settings/Settings'));
const Profile = lazy(() => import('./components/OperativeProfile/OperativeProfile'));
const SupplierProfile = lazy(() => import('./components/SupplierProfile/SupplierProfile'));
const Messages = lazy(() => import('./components/Messages/Inbox/Inbox'));
const Conversation = lazy(() =>
  import('./components/Messages/SingleConversation/SingleConversation'),
);
const JobApplicants = lazy(() => import('./components/JobApplicants/JobApplicants'));
const ResetPassword = lazy(() => import('./components/ResetPassword/ResetPassword'));
const LeaveReview = lazy(() => import('./components/LeaveReview/LeaveReview'));
const AdminModerateReviews = lazy(() => import('./components/Admin/ModerateReviews'));
const AdminModerateSingleReview = lazy(() => import('./components/Admin/ModerateSingleFeedback'));
const Unsubscribe = lazy(() => import('./components/Settings/Unsubscribe'));
const CreateAccommodationAdmin = lazy(() => import('./components/Admin/CreateAccommodation'));
const ListAccommodation = lazy(() => import('./components/Admin/AccommodationList'));
const Accommodation = lazy(() => import('./components/Accommodation/Accommodation'));
const AdminJobsList = lazy(() => import('./components/Admin/JobsList'));
const AdminFeed = lazy(() => import('./components/Admin/SuppliesFeed'));
const AdminJob = lazy(() => import('./components/Admin/IndividualJob'));
const AllSuppliers = lazy(() => import('./components/AllSuppliers/AllSuppliersContainer'));
const CategorySuppliers = lazy(() => import('./components/AllSuppliers/AllSuppliers'));

const CreateAccommodation = lazy(() => import('./components/Accommodation/CreateAccommodation'));
const PostedAccommodation = lazy(() => import('./components/Accommodation/PostedAccommodation'));
const Payment = lazy(() => import('./components/Payment/Payment'));

const Router = ({ authenticated }) => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/" exact component={RootComponent} />
        <Route path="/coming-soon" exact component={ComingSoon} />
        <Route path="/forbidden" exact render={() => <h1>Forbidden</h1>} />
        <Route path="/login" component={Login} />
        <Route path="/admin/login" exact render={() => <Login admin />} />
        <Route path="/register/client" component={RegisterClient} />
        <Route path="/register/operative" component={RegisterOperative} />
        <Route path="/register/supplier" component={RegisterSupplier} />
        <Route path="/register/recruiter" component={RegisterRecruiter} />
        <Route path="/register/accommodation" component={RegisterAccommodation} />
        <Route path="/terms-and-conditions" component={CommonPage} />
        <Route path="/cookie-policy" component={CommonPage} />
        <Route path="/about" component={LandingPage} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/signup" component={Signup} />
        <Route path="/password-reset" exact component={ResetPassword} />
        <Route path="/unsubscribe" exact component={Unsubscribe} />
        {/* Authed Routes */}
        <AuthenticatedRoute path="/jobs/post" component={PostJob} />
        <AuthenticatedRoute path="/jobs/applied" exact component={AppliedJobs} />
        <AuthenticatedRoute path="/jobs/matched" exact component={MatchedJobs} />
        <AuthenticatedRoute path="/jobs/:id/apply" exact component={ApplyJob} />
        <AuthenticatedRoute path="/jobs/:id/edit" exact render={() => <PostJob edit />} />
        <AuthenticatedRoute path="/jobs/:id/applicants" exact component={JobApplicants} />
        <AuthenticatedRoute path="/jobs/:id" component={IndividualJob} />
        <AuthenticatedRoute path="/client/jobs" component={PostedJobs} />
        <AuthenticatedRoute path="/feed/jobs" exact component={Feed} />
        <AuthenticatedRoute path="/feed/supplies" exact component={Feed} />
        <AuthenticatedRoute path="/settings" exact component={Settings} />
        <AuthenticatedRoute path="/operative/:id/profile" exact component={Profile} />
        <AuthenticatedRoute path="/supplier/:id/profile" exact component={SupplierProfile} />
        <AuthenticatedRoute
          path="/supplier/:id/profile/edit"
          exact
          render={props => <SupplierProfile {...props} inEdit />}
        />
        <AuthenticatedRoute
          path="/operative/:id/profile/edit"
          exact
          render={props => <Profile {...props} inEdit />}
        />
        <AuthenticatedRoute path="/messages" exact component={Messages} />
        <AuthenticatedRoute path="/conversation/:id" exact component={Conversation} />
        <AuthenticatedRoute path="/leave-review" exact component={LeaveReview} />
        <AuthenticatedRoute path="/accommodation" exact component={Accommodation} />
        <AuthenticatedRoute path="/suppliers" exact component={AllSuppliers} />
        <AuthenticatedRoute path="/suppliers/category/:id" exact component={CategorySuppliers} />

        <AuthenticatedRoute path="/accommodation/create" exact component={CreateAccommodation} />
        <AuthenticatedRoute path="/accommodation/posted" exact component={PostedAccommodation} />
        <AuthenticatedRoute
          path="/accommodation/:id/edit"
          exact
          render={props => <CreateAccommodation {...props} edit />}
        />
        <AuthenticatedRoute
          path="/accommodation/payment"
          exact
          render={() => <Payment type="accommodation" />}
        />
        <AuthenticatedRoute
          path="/supplier/payment"
          exact
          render={() => <Payment type="supplier" />}
        />
        {/* Admin Routes */}
        <AuthenticatedRoute path="/admin/home" exact component={AdminHome} />
        <AuthenticatedRoute path="/admin/members-list" exact component={MembersList} />
        <AuthenticatedRoute path="/admin/member/:id" exact component={Member} />
        <AuthenticatedRoute path="/admin/suppliers" exact component={AdminSuppliersList} />
        <AuthenticatedRoute path="/admin/moderate-reviews" exact component={AdminModerateReviews} />
        <AuthenticatedRoute
          path="/admin/feedback/:id"
          exact
          component={AdminModerateSingleReview}
        />
        <AuthenticatedRoute path="/admin/accommodation" exact component={ListAccommodation} />
        <AuthenticatedRoute
          path="/admin/accommodation/create"
          exact
          render={props => <CreateAccommodation {...props} admin />}
        />
        <AuthenticatedRoute
          path="/admin/accommodation/:id/edit"
          exact
          render={props => <CreateAccommodation {...props} edit admin />}
        />
        <AuthenticatedRoute path="/admin/jobs-list" exact component={AdminJobsList} />
        <AuthenticatedRoute path="/admin/job/:id" exact component={AdminJob} />
        <AuthenticatedRoute path="/admin/feed" exact component={AdminFeed} />
        <AuthenticatedRoute path="/admin/feed/:id" exact component={FeedPost} />
        {authenticated ? (
          <Route render={() => <NotFound />} />
        ) : (
          <Route render={() => <div>Not allowed</div>} />
        )}
      </Switch>
    </Suspense>
  );
};
Router.propTypes = {
  authenticated: PropTypes.bool.isRequired,
};
export default Router;
