import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import './ApplyJob.scss';

import Button from '../Reusable/Button';
import Loader from '../Loader/Loader';
import getJob from '../../services/GetJob';
import applyForJob from '../../services/ApplyForJob';

const ApplyJob = ({
  match: {
    params: { id },
  },
}) => {
  const [job, setJob] = useState(null);
  const [notes, setNotes] = useState(null);
  const [posting, setPosting] = useState(false);
  const [applied, setApplied] = useState(null);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const fetchJob = async () => {
      const res = await getJob(id);
      setJob(res);
      setApplied(res.applied > 0);
    };

    fetchJob();
  }, []);

  if (!job || applied === null) {
    return <Loader />;
  }

  if (applied) {
    return (
      <div className="job background text-center">
        <div className="overlay" />
        <h3 className="p-3 job__title">You have already applied to this job.</h3>
        <Link to={`/jobs/${id}`}>
          <Button outline className="job__back applied" style={{ margin: '0 auto' }}>
            <i className="far fa-arrow-alt-circle-left" /> Back to job description
          </Button>
        </Link>
      </div>
    );
  }

  const handleChange = e => setNotes(e.target.value);
  const handleSubmit = async () => {
    setPosting(true);
    try {
      await applyForJob(notes, id);
      setPosting(false);
      setRedirect(`/jobs/${id}`);
      toast.success('Successfully applied for job');
      setApplied(true);
    } catch (e) {
      setPosting(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} push />;
  }

  const { jobTitle } = job;

  return (
    <div className="job background">
      <div className="overlay" />
      <h2 className="job__title">{jobTitle}</h2>
      <hr />
      <div className="job__inner">
        <div className="job__inner--content">
          <Link to={`/jobs/${id}`}>
            <Button outline className="job__back">
              <i className="far fa-arrow-alt-circle-left" /> Back to job description
            </Button>
          </Link>
          <div className="job__info">
            <p className="job__info--reference">Application (job ID #{id})</p>
            <Form.Group>
              <Form.Label>Add some notes to your application (optional)</Form.Label>
              <Form.Control
                as="textarea"
                onChange={handleChange}
                rows="5"
                value={notes}
                style={{ resize: 'none' }}
              />
            </Form.Group>
            <Button onClick={handleSubmit}>Send application</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ApplyJob.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }) })
    .isRequired,
};

export default withRouter(ApplyJob);
