/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Button, Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';

const RegisterOperativeForm = ({ regions, certifications, occupations, onSubmit }) => {
  const options = certifications.map(c => ({ value: c.id, label: c.name }));
  const occOptions = occupations.map(o => ({ value: o.id, label: o.name }));
  const [key, setKey] = useState('basicInfo');

  const switchTab = async (k, direction, fields, validateForm) => {
    if (direction === 'back') {
      setKey(k);
    } else {
      const errors = await validateForm();
      if (Object.keys(errors).length) {
        const hasError = Object.keys(errors).map(val => {
          if (fields.includes(val)) {
            return true;
          }
          return false;
        });
        if (hasError.some(Boolean)) {
          // Has errors
          return false;
        }
        setKey(k);
      }
    }
    setKey(k);
    return true;
  };

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        region: '',
        postcode: '',
        email_address: '',
        password: '',
        password_confirmation: '',
        // national_insurance_number: '',
        certification: [],
        occupations: [],
        phone_number: '',
        // utr_number: '',
      }}
      validationSchema={yup.object().shape({
        first_name: yup
          .string()
          .nullable()
          .required('First Name is Required'),
        last_name: yup
          .string()
          .nullable()
          .required('First Name is Required'),
        address_line_1: yup
          .string()
          .nullable()
          .required('Address Line 1 is Required'),
        address_line_2: yup.string(),
        city: yup
          .string()
          .nullable()
          .required('City is Required'),
        region: yup
          .string()
          .nullable()
          .required('Region is Required'),
        postcode: yup
          .string()
          .nullable()
          .required('Postcode is Required'),
        email_address: yup
          .string()
          .email('Email Address is not valid')
          .nullable()
          .required('Email Address is Required'),
        password: yup
          .string()
          .nullable()
          .required('Password is Required')
          .min(6, 'Password is too short, it must be at least 6 characters')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
            'Password should contain both an uppercase and lowercase letter, as well as at least one number',
          ),
        password_confirmation: yup
          .string()
          .nullable()
          .oneOf([yup.ref('password'), null], 'Passwords must match')
          .required('Password Confirmation is Required'),
        // national_insurance_number: yup
        //   .string()
        //   .nullable()
        //   .required('National Insurance Number is Required'),
        certification: yup
          .array()
          .min(1, 'Please choose at least 1 certification')
          .of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            }),
          ),
        occupations: yup
          .array()
          .min(1, 'Please choose at least 1 occupation')
          .of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
            }),
          ),
        phone_number: yup
          .string()
          .nullable()
          .required('Region is Required'),
        // utr_number: yup
        //   .string()
        //   .nullable()
        //   .required('UTR Number is required'),
      })}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        touched,
        setFieldValue,
        setFieldTouched,
        validateForm,
      }) => {
        const basicInfoErrors = [
          'first_name',
          'last_name',
          'occupations',
          // 'utr_number',
          // 'national_insurance_number',
          'certification',
        ];
        const addressErrors = ['address_line_1', 'address_line_2', 'city', 'region', 'postcode'];
        const contactErrors = [
          'email_address',
          'password',
          'password_confirmation',
          'phone_number',
        ];

        return (
          <Form onSubmit={handleSubmit} className="login-form">
            <Tabs activeKey={key}>
              <Tab eventKey="basicInfo" title="Basic Information">
                <BasicInfo
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  occOptions={occOptions}
                  options={options}
                  next={switchTab}
                  basicInfoErrors={basicInfoErrors}
                  validateForm={validateForm}
                />
              </Tab>
              <Tab eventKey="addressInfo" title="Address">
                <AddressInfo
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  regions={regions}
                  next={switchTab}
                  back={() => switchTab('basicInfo', 'back')}
                  addressErrors={addressErrors}
                  validateForm={validateForm}
                />
              </Tab>
              <Tab eventKey="contactInfo" title="Contact Information">
                <ContactInfo
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  back={() => switchTab('addressInfo', 'back')}
                  contactErrors={contactErrors}
                />
              </Tab>
            </Tabs>
          </Form>
        );
      }}
    />
  );
};

RegisterOperativeForm.propTypes = {
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  occupations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default RegisterOperativeForm;

const BasicInfo = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  occOptions,
  options,
  next,
  basicInfoErrors,
  validateForm,
}) => (
  <>
    <Form.Group>
      <Form.Label className="login-form__label">First Name</Form.Label>
      <Form.Control
        name="first_name"
        type="text"
        placeholder="First Name"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.first_name}
        isInvalid={errors.first_name && touched.first_name}
        id="first_name"
      />
      <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="login-form__label">Last Name</Form.Label>
      <Form.Control
        name="last_name"
        type="text"
        placeholder="Last Name"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.last_name}
        isInvalid={errors.last_name && touched.last_name}
        id="last_name"
      />
      <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="login-form__label">Occupation</Form.Label>
      <Select
        name="occupations"
        onChange={option => setFieldValue('occupations', option)}
        onBlur={() => setFieldTouched('occupations', true)}
        value={values.occupations}
        isInvalid={errors.occupations && touched.occupations}
        id="occupations"
        options={occOptions}
        isMulti
        touched={touched.occupations}
      />
      <Form.Control.Feedback type="invalid">{errors.occupations}</Form.Control.Feedback>
    </Form.Group>
    {/* <Form.Group>
      <Form.Label className="login-form__label">UTR Number</Form.Label>
      <Form.Control
        name="utr_number"
        type="text"
        placeholder="UTR Number"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.utr_number}
        isInvalid={errors.utr_number && touched.utr_number}
        id="utr_number"
      />
      <Form.Control.Feedback type="invalid">{errors.utr_number}</Form.Control.Feedback>
    </Form.Group> */}
    {/* <Form.Group>
      <Form.Label className="login-form__label">National Insurance Number</Form.Label>
      <Form.Control
        name="national_insurance_number"
        type="text"
        placeholder="National Insurance Number"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.national_insurance_number}
        isInvalid={errors.national_insurance_number && touched.national_insurance_number}
        id="national_insurance_number"
      />
      <Form.Control.Feedback type="invalid">
        {errors.national_insurance_number}
      </Form.Control.Feedback>
    </Form.Group> */}
    <Form.Group>
      <Form.Label className="login-form__label">Certification</Form.Label>
      <Select
        name="certification"
        onChange={option => setFieldValue('certification', option)}
        onBlur={() => setFieldTouched('certification', true)}
        value={values.certification}
        isInvalid={errors.certification && touched.certification}
        id="certification"
        options={options}
        isMulti
        touched={touched.certification}
      />
      <Form.Control.Feedback type="invalid">{errors.certification}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Button
        className="float-right mt-3 login-form__submit"
        variant="outline-dark"
        type="button"
        disabled={
          errors.first_name || errors.last_name || errors.occupations || errors.certification
        }
        onClick={async () => {
          await validateForm();
          next('addressInfo', 'forward', basicInfoErrors, validateForm);
        }}
      >
        Next
      </Button>
    </Form.Group>
  </>
);
const AddressInfo = ({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  regions,
  next,
  back,
  validateForm,
  addressErrors,
}) => (
  <>
    <Form.Group>
      <Form.Label className="login-form__label">Address Line 1</Form.Label>
      <Form.Control
        name="address_line_1"
        type="text"
        placeholder="Address Line 1"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.address_line_1}
        isInvalid={errors.address_line_1 && touched.address_line_1}
        id="address_line_1"
      />
      <Form.Control.Feedback type="invalid">{errors.address_line_1}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="login-form__label">Address Line 2</Form.Label>
      <Form.Control
        name="address_line_2"
        type="text"
        placeholder="Address Line 2"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.address_line_2}
        isInvalid={errors.address_line_2 && touched.address_line_2}
        id="address_line_2"
      />
      <Form.Control.Feedback type="invalid">{errors.address_line_2}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="login-form__label">City</Form.Label>
      <Form.Control
        type="text"
        name="city"
        placeholder="City"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.city}
        isInvalid={errors.city && touched.city}
        id="city"
      />
      <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="login-form__label">Region</Form.Label>
      <Form.Control
        as="select"
        name="region"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.region}
        isInvalid={errors.region && touched.region}
        id="region"
      >
        <option value="" readOnly disabled>
          Please Select a Region
        </option>
        {regions.map(region => (
          <option key={region.id} value={region.id}>
            {region.name}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{errors.region}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="login-form__label">Postcode</Form.Label>
      <Form.Control
        name="postcode"
        type="text"
        placeholder="Postcode"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.postcode}
        isInvalid={errors.postcode && touched.postcode}
        id="postcode"
      />
      <Form.Control.Feedback type="invalid">{errors.postcode}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Button
        className="float-left mt-3 login-form__submit"
        variant="outline-dark"
        type="button"
        onClick={back}
      >
        Back
      </Button>
    </Form.Group>
    <Form.Group>
      <Button
        className="float-right mt-3 login-form__submit"
        variant="outline-dark"
        type="button"
        disabled={addressErrors}
        onClick={() => next('contactInfo', 'forward', addressErrors, validateForm)}
      >
        Next
      </Button>
    </Form.Group>
  </>
);
const ContactInfo = ({ handleChange, handleBlur, values, touched, errors, isSubmitting, back }) => (
  <>
    <Form.Group>
      <Form.Label className="login-form__label">Phone Number</Form.Label>
      <Form.Control
        name="phone_number"
        type="text"
        placeholder="Phone Number"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.phone_number}
        isInvalid={errors.phone_number && touched.phone_number}
        id="national_insurance_number"
      />
      <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="login-form__label">Email Address</Form.Label>
      <Form.Control
        name="email_address"
        type="text"
        placeholder="Email Address"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email_address}
        isInvalid={errors.email_address && touched.email_address}
        id="email_address"
        autoComplete="username"
      />
      <Form.Control.Feedback type="invalid">{errors.email_address}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="login-form__label">Password</Form.Label>
      <Form.Control
        name="password"
        type="password"
        placeholder="Password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        isInvalid={errors.password && touched.password}
        id="password"
        autoComplete="new-password"
      />
      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Form.Label className="login-form__label">Confirm Password</Form.Label>
      <Form.Control
        name="password_confirmation"
        type="password"
        placeholder="Confirm Password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password_confirmation}
        isInvalid={errors.password_confirmation && touched.password_confirmation}
        id="password_confirmation"
        autoComplete="new-password"
      />
      <Form.Control.Feedback type="invalid">{errors.password_confirmation}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group>
      <Button
        className="float-left mt-3 login-form__submit"
        variant="outline-dark"
        type="button"
        onClick={back}
      >
        Back
      </Button>
    </Form.Group>
    <Form.Group>
      <Button
        className="float-right mt-3 login-form__submit"
        variant="outline-dark"
        type="submit"
        disabled={isSubmitting || Object.keys(errors).length}
      >
        Register
      </Button>
    </Form.Group>
  </>
);
