import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import './AppliedJobs.scss';

import AppliedJob from './AppliedJob';
import Loader from '../Loader/Loader';
import { getAppliedJobs } from '../../services';

const AppliedJobs = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      const res = await getAppliedJobs();
      setJobs(res);
    };

    fetchJobs();
  }, []);

  if (!jobs.length) {
    return <Loader />;
  }

  return (
    <div className="background applied-jobs">
      <div className="overlay" />
      <h2 className="applied-jobs__title">Applied Jobs</h2>
      <div className="applied-jobs__inner">
        <Row>
          {jobs.map(job => (
            <AppliedJob key={job.id} {...job} />
          ))}
        </Row>
      </div>
    </div>
  );
};

export default AppliedJobs;
