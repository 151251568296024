import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const createAccommodation = async payload => {
  const { data } = await postRequest(
    true,
    Endpoints.CREATE_ACCOMMODATION,
    payload,
    {},
    'POST',
    null,
  );
  return camelCaseKeys(data.data);
};

export default createAccommodation;
