import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../../constants';
import { postRequest } from '../../helpers/requestHelpers';

const approveFeedback = async id => {
  const { data } = await postRequest(true, Endpoints.APPROVE_FEEDBACK, {
    feedback_id: id,
  });
  return camelCaseKeys(data.data);
};

export default approveFeedback;
