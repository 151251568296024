import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

const Loader = props => (
  <div className="loader" {...props}>
    <ScaleLoader loading />
  </div>
);

export default Loader;
