import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { withRouter } from 'react-router-dom';

import getSupplyRequests from '../../../services/Feed/getSupplyRequests';
import { Loader } from '../../Loader';
import SuppliesFeedPost from './SuppliesFeedPost';
import SuppliesFeedCreate from './SuppliesFeedCreate';
import { Pagination } from '../../Reusable';
import AccountTypes from '../../../constants/accountTypes';
import { createTransaction } from '../../../services';

const SuppliesFeed = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [feedPosts, setFeedPosts] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const fetchFeed = async (p = 1) => {
    setLoading(true);
    setPage(p);
    const res = await getSupplyRequests(p);
    setFeedPosts(res.data);
    setTotalPages(res.meta.totalPages);
    setLoading(false);
  };

  useEffect(() => {
    fetchFeed();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="supplies_feed">
      <Container>
        {Number(localStorage.getItem('account_type')) !== AccountTypes.OPERATIVE.id &&
          Number(localStorage.getItem('account_type')) !== AccountTypes.RECRUITER.id && (
            <SuppliesFeedCreate fetchPosts={fetchFeed} />
          )}
        <hr />
        <h3 className="feed__inner__title">Today&#39;s Activity</h3>

        {feedPosts.length ? (
          feedPosts.map(post => <SuppliesFeedPost key={post.id} {...post} />)
        ) : (
          <h3>There are no posts yet</h3>
        )}
      </Container>
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={fetchFeed} />
    </div>
  );
};

export default withRouter(SuppliesFeed);
