import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../../constants';
import { postRequest } from '../../helpers/requestHelpers';

const rejectFeedback = async (id, reason) => {
  const { data } = await postRequest(true, Endpoints.REJECT_FEEDBACK, {
    feedback_id: id,
    rejected_reason: reason,
  });
  return camelCaseKeys(data.data);
};

export default rejectFeedback;
