import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const adminGetMembers = async (type = null, keyword = null) => {
  const { data } = await getRequest(false, Endpoints.ADMIN_GET_MEMBERS, { type, keyword });
  return camelCaseKeys(data.data);
};

export default adminGetMembers;
