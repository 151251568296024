import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const checkPagePermissions = async (accountId, accountType) => {
  const { data } = await postRequest(true, Endpoints.CHECK_PAGE_PERMISSIONS, {
    account_id: accountId,
    account_type: accountType,
  });
  return camelCaseKeys(data);
};

export default checkPagePermissions;
