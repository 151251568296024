import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../../constants';
import { getRequest } from '../../helpers/requestHelpers';

const getSupplyRequests = async (page, keyword) => {
  const { data } = await getRequest(true, Endpoints.GET_SUPPLIES_FEED, { page, keyword });
  return camelCaseKeys(data);
};

export default getSupplyRequests;
