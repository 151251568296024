import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getAppliedJobs = async () => {
  const { data } = await getRequest(true, Endpoints.APPLIED_JOBS);
  return camelCaseKeys(data.data);
};

export default getAppliedJobs;
