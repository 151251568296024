import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';

import './IndividualJob.scss';
import Button from '../Reusable/Button';
import Loader from '../Loader/Loader';
import getJob from '../../services/GetJob';

const IndividualJob = ({
  match: {
    params: { id },
  },
}) => {
  const [job, setJob] = useState(null);

  useEffect(() => {
    const fetchJob = async () => {
      const res = await getJob(id);
      setJob(res);
    };

    fetchJob();
  }, [id]);

  if (!job) {
    return <Loader />;
  }

  const {
    client,
    recruiter,
    placementType,
    jobStartDate,
    jobEndDate,
    payDetails,
    regions,
    jobCertifications,
    jobDescription,
    createdAt,
    jobTitle,
    occupations,
    additionalLocationDetails,
    accommodationIncluded,
    loyaltyBonusOptin,
  } = job;

  const ApplyButton = ({ hide }) => (
    <Link to={`/jobs/${id}/apply`}>
      <Button className={`job__apply ${hide && 'd-sm-none'}`}>Apply for job</Button>
    </Link>
  );
  ApplyButton.defaultProps = { hide: false };
  ApplyButton.propTypes = { hide: PropTypes.bool };

  const company = client || recruiter;

  return (
    <div className="job background">
      <div className="overlay" />
      <h2 className="job__title">{jobTitle}</h2>
      <div className="job__inner">
        <div className="job__inner--content">
          <Link to="/feed/jobs">
            <Button outline className="job__back">
              <i className="far fa-arrow-alt-circle-left" /> Back to feed
            </Button>
          </Link>
          <div className="job__info">
            <p className="job__info--company-name">{company.companyName}</p>
            <p className="job__info--type">{placementType.name}</p>
            {jobStartDate && jobEndDate && (
              <p className="job__info--dates">
                {moment(jobStartDate).format('DD/MM/YYYY')} -{' '}
                {moment(jobEndDate).format('DD/MM/YYYY')}
              </p>
            )}
            <p className="job__info--money">{payDetails}</p>
            <p className="job__info--location">{regions.map(region => region.name).join(', ')}</p>
            <p className="job__info--additional-location-details">{additionalLocationDetails}</p>
            {Boolean(loyaltyBonusOptin) && (
              <>
                <p className="job__info--loyalty-bonus">
                  <i className="fas fa-check pr-2" />
                  This company has opted in to paying a loyalty bonus, please email signed
                  timesheets to info@directlabourexchange.com on completion
                </p>
              </>
            )}
            <p className="job__info--accommodation-included">
              {accommodationIncluded ? (
                <>
                  <i className="fas fa-check pr-2" />
                  Accommodation Included
                </>
              ) : (
                <>
                  <i className="fas fa-times pr-2" />
                  Accommodation Not Included
                </>
              )}
            </p>
            <p className="job__info--occupations">
              <strong>Occupations: </strong>
              {occupations.map(occ => occ.name).join(', ')}
            </p>
            <p className="job__info--certification">
              <strong>Certifications: </strong>
              {jobCertifications.map(cert => cert.name).join(', ')}
            </p>
            {/* <ApplyButton hide /> */}
            <p
              className="job__info--description"
              dangerouslySetInnerHTML={{ __html: jobDescription }}
            />
            <p className="job__info--created-at">
              Created on {moment(createdAt).format('DD/MM/YYYY HH:MM')}
            </p>
            <ApplyButton />
          </div>
        </div>
      </div>
    </div>
  );
};

IndividualJob.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }) })
    .isRequired,
};

export default withRouter(IndividualJob);
