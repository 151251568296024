import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getConversations = async page => {
  const { data } = await getRequest(true, Endpoints.GET_CONVERSATIONS, { page });
  return camelCaseKeys(data);
};

export default getConversations;
