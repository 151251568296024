import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col } from 'react-bootstrap';

const AppliedJob = ({ jobTitle, placementType, jobStartDate, jobEndDate, applications }) => (
  <Col xs="12" md="6" lg="4">
    <div
      className={`applied-jobs__job ${jobEndDate &&
        new Date(jobEndDate) < new Date() &&
        'expired'}`}
    >
      {jobEndDate && new Date(jobEndDate) < new Date() && (
        <div className="expired">
          <div className="expired__overlay" />
          <div className="expired__content">
            <h2>Expired</h2>
          </div>
        </div>
      )}
      <h4 className="applied-jobs__job__title">{jobTitle}</h4>
      <p className="applied-jobs__job__placement-type">{placementType.name}</p>
      {jobStartDate && jobEndDate && (
        <p className="applied-jobs__job__dates">
          {moment(jobStartDate).format('DD/MM/YYYY')} - {moment(jobEndDate).format('DD/MM/YYYY')}
        </p>
      )}
      <p className="applied-jobs__job__applied-date">
        Applied On {moment(applications[0].createdAt).format('DD/MM/YYYY')}
      </p>
      <p className="applied-jobs__job__status">Status: {applications[0].status.name}</p>
      {/* <Link to={`/jobs/${id}`}>
        <Button className="applied-jobs__job__view-button">View Application</Button>
      </Link> */}
    </div>
  </Col>
);

AppliedJob.propTypes = {
  jobTitle: PropTypes.string.isRequired,
  placementType: PropTypes.shape({ name: PropTypes.string }).isRequired,
  jobStartDate: PropTypes.string.isRequired,
  jobEndDate: PropTypes.string.isRequired,
  applications: PropTypes.arrayOf(PropTypes.shape({ createdAt: PropTypes.string.isRequired }))
    .isRequired,
};
export default AppliedJob;
