import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';

import { getJobs } from '../../../services';
import { Loader } from '../../Loader';
import Filter from './FeedJobFilter';

import './FeedJobs.scss';
import JobCard from '../../JobCard/JobCard';
import Pagination from '../../Reusable/Pagination';

const FeedJobs = () => {
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [selectedPlacementTypes, setSelectedPlacementTypes] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  const fetchJobs = async (regions = [], certifications = [], placementTypes = [], p = 1) => {
    const region = regions && regions.length ? regions.map(o => o.value) : [];
    const certs = certifications && certifications.length ? certifications.map(o => o.value) : [];
    const types = placementTypes && placementTypes.length ? placementTypes.map(o => o.value) : [];
    setLoading(true);
    const res = await getJobs(region, certs, types, p);
    setJobs(res.data);
    setTotalPages(res.meta.totalPages);
    setLoading(false);
  };

  const onPageChange = async p => {
    setPage(p);
    await fetchJobs(selectedRegions, selectedCertifications, selectedPlacementTypes, p);
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const onRegionChange = selectedOptions => {
    setSelectedRegions(selectedOptions);
  };
  const onCertificationChange = selectedOptions => {
    setSelectedCertifications(selectedOptions);
  };
  const onPlacementTypeChange = selectedOptions => {
    setSelectedPlacementTypes(selectedOptions);
  };

  return (
    <>
      <h3 className="feed__inner__title">Today&#39;s Activity</h3>

      <Filter
        onRegionChange={onRegionChange}
        onCertificationChange={onCertificationChange}
        onPlacementTypeChange={onPlacementTypeChange}
        selectedRegions={selectedRegions}
        selectedCertifications={selectedCertifications}
        selectedPlacementTypes={selectedPlacementTypes}
        setSelectedRegions={setSelectedRegions}
        setSelectedCertifications={setSelectedCertifications}
        setSelectedPlacementTypes={setSelectedPlacementTypes}
        fetchJobs={fetchJobs}
      />
      {!jobs.length && !loading && <h4>No Jobs Found</h4>}
      {loading ? (
        <Loader />
      ) : (
        <>
          <Row>
            {jobs.length ? (
              jobs.map(job => <JobCard key={job.id} {...job} />)
            ) : (
              <h3>No jobs found</h3>
            )}
          </Row>

          <Pagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </>
      )}
    </>
  );
};

export default FeedJobs;
