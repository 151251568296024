import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getMatchedJobs = async (page = 1) => {
  const { data } = await getRequest(true, Endpoints.GET_MATCHED_JOBS, { page });
  return camelCaseKeys(data);
};

export default getMatchedJobs;
