import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { Button } from '../Reusable';

const ResetPasswordForm = ({ onSubmit }) => {
  const validationSchema = {
    password: yup
      .string()
      .nullable()
      .required('Password is Required')
      .min(6, 'Password is too short, it must be at least 6 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'Password should contain both an uppercase and lowercase letter, as well as at least one number',
      ),
    password_confirmation: yup
      .string()
      .nullable()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Password Confirmation is Required'),
  };

  const initialValues = {
    password: '',
    password_confirmation: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape(validationSchema)}
      onSubmit={onSubmit}
      render={({ handleSubmit, handleChange, handleBlur, values, errors, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Group>
            <Form.Label className="login-form__label">Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              isInvalid={errors.password}
              id="password"
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label className="login-form__label">Confirm Password</Form.Label>
            <Form.Control
              name="password_confirmation"
              type="password"
              placeholder="Confirm Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password_confirmation}
              isInvalid={errors.password_confirmation}
              id="password"
            />
            <Form.Control.Feedback type="invalid">
              {errors.password_confirmation}
            </Form.Control.Feedback>
          </Form.Group>
          <Button className="mt-3" type="submit" disabled={isSubmitting}>
            Reset Password
          </Button>
        </Form>
      )}
    />
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
