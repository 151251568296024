import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const removeCv = async id => {
  const { data } = await postRequest(
    true,
    Endpoints.DELETE_OPERATIVE_PROFILE_CV,
    {},
    { id },
    'PUT',
    null,
  );
  return data.data;
};

export default removeCv;
