import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const changePassword = async (
  accountId,
  password,
  passwordConfirmation,
  hash = null,
  currentPassword = null,
) => {
  const { data } = await postRequest(false, Endpoints.CHANGE_PASSWORD, {
    account_id: accountId,
    password,
    password_confirmation: passwordConfirmation,
    token: hash,
    current_password: currentPassword,
  });
  return camelCaseKeys(data.data);
};

export default changePassword;
