import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';
import useIsMobile from '../../hooks/useIsMobile';
import getCompanyDetails from '../../services/GetCompanyDetails';
import Loader from '../Loader/Loader';
import getCommonPages from '../../services/GetCommonPages';

const Footer = () => {
  const isMobile = useIsMobile();

  const [details, setDetails] = useState();
  const [pages, setPages] = useState();

  useEffect(() => {
    const fetchDetails = async () => {
      const res = await getCompanyDetails();
      const pgs = await getCommonPages();
      setDetails(res);
      setPages(pgs);
    };

    fetchDetails();
  }, []);

  if (!details || !pages) {
    return <Loader />;
  }

  const {
    companyName,
    companyAddress,
    tradingNameOf,
    emailAddress,
    companyNumber,
    vatNumber,
    phone,
  } = details;
  return (
    <div className="footer">
      {isMobile ? (
        <>
          <p>{companyName}</p>
          <p>{companyAddress}</p>
          <p>
            <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
          </p>
          <p>{phone}</p>
          <hr />
          <p>
            <small>
              {companyName} is a trading name of {tradingNameOf}
            </small>
            <br />
            <small>
              {tradingNameOf} is a company registered in England and Wales with company number{' '}
              {companyNumber} | VAT Number {vatNumber}
            </small>
            <br />
            <small>
              &copy; Copyright {companyName} {new Date().getFullYear()}
            </small>
          </p>
          <p>
            {pages.length
              ? pages.map((page, i) => (
                  <React.Fragment key={page.name}>
                    <Link to={page.link} key={page.name}>
                      {page.name}
                    </Link>{' '}
                    {i + 1 !== pages.length ? ' | ' : ''}
                  </React.Fragment>
                ))
              : ''}
          </p>
        </>
      ) : (
        <>
          <p>
            {companyName} | {companyAddress} | <a href={`mailto:${emailAddress}`}>{emailAddress}</a>{' '}
            | {phone}
          </p>
          <p>
            <small>
              {companyName} is a trading name of {tradingNameOf} | {tradingNameOf} is a company
              registered in England and Wales with company number {companyNumber} | VAT Number{' '}
              {vatNumber} | &copy; Copyright {companyName} {new Date().getFullYear()}
            </small>{' '}
            |{' '}
            {pages.length
              ? pages.map((page, i) => (
                  <React.Fragment key={page.name}>
                    <Link to={page.link}>{page.name}</Link> {i + 1 !== pages.length ? ' | ' : ''}
                  </React.Fragment>
                ))
              : ''}
          </p>
        </>
      )}
    </div>
  );
};

export default Footer;
