import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

import getJob from '../../services/GetJob';
import { Loader } from '../Loader';
import Nav from './Nav';
import getApplicants from '../../services/GetJobApplicants';
import deleteJob from '../../services/DeleteJob';
import getSupplyFeedPost from '../../services/Feed/getSupplyFeedPost';
import deletePost from '../../services/Feed/deletePost';
import SuppliesFeedPostImage from '../Feed/SuppliesFeed/SuppliesFeedPostImage';

const FeedPost = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getSupplyFeedPost(id);
      setPost(res);
      setLoading(false);
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  const handleDeletePost = async () => {
    // eslint-disable-next-line
    if (window.confirm('Are you sure you want to delete this post?')) {
      await deletePost(id);
      history.push('/admin/feed');
    }
  };

  const { account, title, description, images, region, createdAt } = post;
  console.log(post);
  let name = 'Unknown';
  if (account && account.recruiter) {
    name = account.recruiter.companyName;
  }
  if (account && account.client) {
    name = account.client.companyName;
  }
  if (account && account.supplier) {
    name = account.supplier.companyName;
  }
  if (account && !account.recruiter && !account.client && !account.supplier) {
    name = `${account.firstName} ${account.lastName}`;
  }

  return (
    <>
      <Nav />
      <Container>
        <h1>{title}</h1>
        <p>
          <strong>Posted by: </strong>
          {name} - {region.name}
        </p>
        <p>
          <strong>Description: </strong>

          {description}
        </p>
        <h4 className="supplies_feed__post--date">
          {' '}
          {moment(createdAt).format('DD MMMM [at] kk:mm')}{' '}
        </h4>
        {images && Boolean(images.length) && (
          <>
            <div className="supplies_feed__post--images-container">
              {' '}
              {images.map(image => (
                <SuppliesFeedPostImage {...image} />
              ))}{' '}
            </div>{' '}
          </>
        )}
        <hr />
        <Button variant="danger" onClick={handleDeletePost}>
          Delete Post
        </Button>
        <br />
        <small>This will immediately remove the post from the site</small>
      </Container>
    </>
  );
};

FeedPost.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string.isRequired }) })
    .isRequired,
};
export default withRouter(FeedPost);
