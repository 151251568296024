import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../../constants';
import { postRequest } from '../../helpers/requestHelpers';

const updateFeedback = async (id, rating, content) => {
  const { data } = await postRequest(
    true,
    Endpoints.UPDATE_FEEDBACK,
    { rating, content },
    { id },
    'PUT',
  );
  return camelCaseKeys(data.data);
};

export default updateFeedback;
