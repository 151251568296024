import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Button, Row, Col } from 'react-bootstrap';
// import ReCaptcha from 'react-recaptcha';

const RegisterClientForm = ({ regions, onSubmit }) => {
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://www.google.com/recaptcha/api.js';
  //   script.async = true;
  //   script.defer = true;
  //   document.body.appendChild(script);
  // }, []);

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        region: '',
        postcode: '',
        company_registration_number: '',
        email_address: '',
        password: '',
        password_confirmation: '',
        company_name: '',
        // recaptcha: '',
      }}
      validationSchema={yup.object().shape({
        first_name: yup
          .string()
          .nullable()
          .required('First Name is Required'),
        last_name: yup
          .string()
          .nullable()
          .required('First Name is Required'),
        address_line_1: yup
          .string()
          .nullable()
          .required('Address Line 1 is Required'),
        address_line_2: yup.string(),
        city: yup
          .string()
          .nullable()
          .required('City is Required'),
        region: yup
          .string()
          .nullable()
          .required('Region is Required'),
        postcode: yup
          .string()
          .nullable()
          .required('Postcode is Required'),
        company_registration_number: yup
          .string()
          .nullable()
          .required('Company Registration Number is Required'),
        email_address: yup
          .string()
          .email('Email Address is not valid')
          .nullable()
          .required('Email Address is Required'),
        password: yup
          .string()
          .nullable()
          .required('Password is Required')
          .min(6, 'Password is too short, it must be at least 6 characters')
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
            'Password should contain both an uppercase and lowercase letter, as well as at least one number',
          ),
        password_confirmation: yup
          .string()
          .nullable()
          .oneOf([yup.ref('password'), null], 'Passwords must match')
          .required('Password Confirmation is Required'),
        company_name: yup
          .string()
          .nullable()
          .required('Company Name is Required'),
        // recaptcha: yup.string().required('Please tick this field'),
      })}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        touched,
        // setFieldValue,
        // setFieldError,
      }) => (
        <Form onSubmit={handleSubmit} className="login-form">
          <Row>
            <Col xs="12" md="4">
              <Form.Group>
                <Form.Label className="login-form__label">First Name</Form.Label>
                <Form.Control
                  name="first_name"
                  type="text"
                  placeholder="First Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.first_name}
                  isInvalid={errors.first_name && touched.first_name}
                  id="first_name"
                />
                <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12" md="4">
              <Form.Group>
                <Form.Label className="login-form__label">Last Name</Form.Label>
                <Form.Control
                  name="last_name"
                  type="text"
                  placeholder="Last Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.last_name}
                  isInvalid={errors.last_name && touched.last_name}
                  id="last_name"
                />
                <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12" md="4">
              <Form.Group>
                <Form.Label className="login-form__label">Company Name</Form.Label>
                <Form.Control
                  name="company_name"
                  type="text"
                  placeholder="Company Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.company_name}
                  isInvalid={errors.company_name && touched.company_name}
                  id="company_name"
                />
                <Form.Control.Feedback type="invalid">{errors.company_name}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <Form.Group>
                <Form.Label className="login-form__label">Address Line 1</Form.Label>
                <Form.Control
                  name="address_line_1"
                  type="text"
                  placeholder="Address Line 1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_line_1}
                  isInvalid={errors.address_line_1 && touched.address_line_1}
                  id="address_line_1"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address_line_1}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12" md="6">
              <Form.Group>
                <Form.Label className="login-form__label">Address Line 2</Form.Label>
                <Form.Control
                  name="address_line_2"
                  type="text"
                  placeholder="Address Line 2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_line_2}
                  isInvalid={errors.address_line_2 && touched.address_line_2}
                  id="address_line_2"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address_line_2}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="4">
              <Form.Group>
                <Form.Label className="login-form__label">City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder="City"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  isInvalid={errors.city && touched.city}
                  id="city"
                />
                <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12" md="4">
              <Form.Group>
                <Form.Label className="login-form__label">Region</Form.Label>
                <Form.Control
                  as="select"
                  name="region"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.region}
                  isInvalid={errors.region && touched.region}
                  id="region"
                >
                  <option value="" readOnly disabled>
                    Please Select a Region
                  </option>
                  {regions.map(region => (
                    <option key={region.id} value={region.id}>
                      {region.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.region}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12" md="4">
              <Form.Group>
                <Form.Label className="login-form__label">Postcode</Form.Label>
                <Form.Control
                  name="postcode"
                  type="text"
                  placeholder="Postcode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.postcode}
                  isInvalid={errors.postcode && touched.postcode}
                  id="postcode"
                />
                <Form.Control.Feedback type="invalid">{errors.postcode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <Form.Group>
                <Form.Label className="login-form__label">Company Registration Number</Form.Label>
                <Form.Control
                  name="company_registration_number"
                  type="text"
                  placeholder="Company Registration Number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.company_registration_number}
                  isInvalid={
                    errors.company_registration_number && touched.company_registration_number
                  }
                  id="company_registration_number"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.company_registration_number}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12" md="6">
              <Form.Group>
                <Form.Label className="login-form__label">Email Address</Form.Label>
                <Form.Control
                  name="email_address"
                  type="text"
                  placeholder="Email Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email_address}
                  isInvalid={errors.email_address && touched.email_address}
                  id="email_address"
                  autoComplete="username"
                />
                <Form.Control.Feedback type="invalid">{errors.email_address}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="6">
              <Form.Group>
                <Form.Label className="login-form__label">Password</Form.Label>
                <Form.Control
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  isInvalid={errors.password && touched.password}
                  id="password"
                  autoComplete="new-password"
                />
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12" md="6">
              <Form.Group>
                <Form.Label className="login-form__label">Confirm Password</Form.Label>
                <Form.Control
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password_confirmation}
                  isInvalid={errors.password_confirmation && touched.password_confirmation}
                  id="password_confirmation"
                  autoComplete="new-password"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Group>
                <ReCaptcha
                  sitekey="6LdIrLMUAAAAAMIUWOsTo2oU43uQDafoWKuE_zsN"
                  render="explicit"
                  verifyCallback={response => {
                    setFieldValue('recaptcha', response);
                    setFieldError('recaptcha', response ? null : 'Please tick this field');
                  }}
                  isInvalid={errors.recaptcha && touched.recaptcha}
                />
                {errors.recaptcha && touched.recaptcha && (
                  <p className="invalid-feedback" style={{ display: 'block' }}>
                    {errors.recaptcha}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col>
              <Form.Group>
                <Button
                  className="float-right mt-3"
                  variant="outline-dark"
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length}
                >
                  Register
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

RegisterClientForm.propTypes = {
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default RegisterClientForm;
