import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const gdprErasure = async id => {
  const { data } = await postRequest(true, Endpoints.GDPR_ERASE, {}, { id }, 'DELETE');
  return camelCaseKeys(data.data);
};

export default gdprErasure;
