import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const acceptApplicant = async (jobId, applicantId) => {
  const { data } = await postRequest(
    true,
    Endpoints.ACCEPT_APPLICANT,
    {},
    { jobId, applicantId },
    'PUT',
  );
  return camelCaseKeys(data.data);
};

export default acceptApplicant;
