import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import { Loader } from '../Loader';
import { getCommonPages } from '../../services';

const CommonPage = ({ location }) => {
  const [content, setContent] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    const getContent = async () => {
      const res = await getCommonPages();
      setContent(res.find(r => r.link === location.pathname).content);
      setTitle(res.find(r => r.link === location.pathname).name);
    };

    getContent();
  }, [location.pathname]);

  if (!content || !title) {
    return <Loader />;
  }

  return (
    <Container className="pt-4">
      <h2>
        <strong>{title}</strong>
      </h2>
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </Container>
  );
};

CommonPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(CommonPage);
