import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getCompanyDetails = async () => {
  const { data } = await getRequest(false, Endpoints.GET_COMPANY_DETAILS);
  return camelCaseKeys(data.data);
};

export default getCompanyDetails;
