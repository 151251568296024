import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const createConversation = async (participants, message, name = null) => {
  const { data } = await postRequest(
    true,
    Endpoints.CREATE_CONVERSATION,
    { participants, initial_message: message, name },
    {},
  );

  return camelCaseKeys(data);
};

export default createConversation;
