import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const registerRecruiter = async payload => {
  const { data } = await postRequest(false, Endpoints.POST_REGISTER_RECRUITER, payload);
  return camelCaseKeys(data.data);
};

export default registerRecruiter;
