import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const doesAccountExist = async (emailAdress, type) => {
  const { data } = await getRequest(false, Endpoints.DOES_ACCOUNT_EXIST, {
    email_address: emailAdress,
    account_type: type,
  });
  return camelCaseKeys(data.data);
};

export default doesAccountExist;
