import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const logout = async () => {
  const { data } = await postRequest(true, Endpoints.LOGOUT);

  return camelCaseKeys(data);
};

export default logout;
