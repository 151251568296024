import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import './Feed.scss';

import AccountTypes from '../../constants/accountTypes';
import FeedJobs from './JobFeed/FeedJobs';
import SuppliesFeed from './SuppliesFeed/SuppliesFeed';

const Feed = ({ location }) => {
  const accountType = Number(localStorage.getItem('account_type'));

  let feed = null;

  if (accountType === AccountTypes.OPERATIVE.id || location.pathname.includes('/jobs')) {
    feed = <FeedJobs />;
  }

  if (accountType === AccountTypes.SUPPLIER.id || location.pathname.includes('/supplies')) {
    feed = <SuppliesFeed />;
  }

  return (
    <div className="feed">
      <h2 className="feed__title">Welcome Back!</h2>
      <div className={`feed__inner ${location.pathname.includes('/supplies') && 'p-0'}`}>
        <div>{feed}</div>
      </div>
    </div>
  );
};

Feed.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withRouter(Feed);
