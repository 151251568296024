import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const login = async (emailAddress, password, type) => {
  const { data } = await postRequest(false, Endpoints.LOGIN, {
    email_address: emailAddress,
    password,
    account_type: type,
  });

  return camelCaseKeys(data);
};

export default login;
