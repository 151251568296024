import React, { createContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import appReducer from './reducer';

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  // https://github.com/nathanbuchar/react-hook-thunk-reducer/blob/master/src/thunk-reducer.js
  const [hookState, setHookState] = useState(appReducer());
  const state = useRef(hookState);
  const getState = () => state.current;
  const setState = newState => {
    state.current = newState;
    setHookState(newState);
  };

  const dispatch = action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }
    return setState(appReducer(getState(), action));
  };

  const value = { state: hookState, dispatch };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

AppContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export { AppContext, AppContextProvider };
