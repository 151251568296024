import * as yup from 'yup';
import moment from 'moment';
import { get } from 'lodash';

export const validationSchema = {
  title: yup
    .string()
    .nullable()
    .required('Job Title is Required'),
  description: yup
    .string()
    .nullable()
    .required('Job Description is Required'),
  placement_type: yup
    .string()
    .nullable()
    .required('Placement Type is Required'),
  pay_details: yup
    .string()
    .nullable()
    .required('Pay Details is Required'),
  region: yup
    .string()
    .nullable()
    .required('Region is Required'),
  certification: yup
    .array()
    .min(1, 'Please choose at least 1 certification')
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
    ),
  occupations: yup
    .array()
    .min(1, 'Please choose at least 1 occupation')
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      }),
    ),
  start_date: yup
    .string()
    .nullable()
    .required(),
  end_date: yup
    .string()
    .nullable()
    .required(),
  additional_location_details: yup
    .string()
    .nullable()
    .required('Location Details are Required'),
  accommodation_included: yup.bool(),
  loyalty_bonus_optin: yup.bool(),
};

export const getInitialValues = (job = null) => {
  return {
    title: get(job, 'jobTitle') || '',
    description: get(job, 'jobDescription') || '',
    placement_type: get(job, 'placementType.id') || '',
    pay_details: get(job, 'payDetails') || '',
    certification: get(job, 'jobCertifications')
      ? get(job, 'jobCertifications').map(c => ({ ...c, label: c.name, value: c.id }))
      : [],
    occupations: get(job, 'occupations')
      ? get(job, 'occupations').map(o => ({ ...o, label: o.name, value: o.id }))
      : [],
    start_date: get(job, 'jobStartDate') ? moment(job.jobStartDate) : null,
    end_date: get(job, 'jobEndDate') ? moment(job.jobEndDate) : null,
    accommodation_included: get(job, 'accommodationIncluded') || false,
    loyalty_bonus_optin: get(job, 'loyaltyBonusOptin') || false,
    region: get(job, 'regions[0].id') || '',
    additional_location_details: get(job, 'additionalLocationDetails') || '',
  };
};
