import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getCommonPages = async () => {
  const { data } = await getRequest(false, Endpoints.GET_PAGES);
  return camelCaseKeys(data.data);
};

export default getCommonPages;
