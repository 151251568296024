import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../../constants';
import { getRequest } from '../../helpers/requestHelpers';

const getJobs = async (regions, certifications, placementType, page, admin = false) => {
  const { data } = await getRequest(true, Endpoints.GET_JOBS_FEED, {
    regions,
    certifications,
    placementType,
    page,
    admin,
  });
  return camelCaseKeys(data);
};

export default getJobs;
