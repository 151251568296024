import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Image } from 'react-bootstrap';

import Modal from '../../Modal/Modal';
import useIsMobile from '../../../hooks/useIsMobile';

const SuppliesFeedPostImage = ({ imagePath }) => {
  const [showModal, setShowModal] = useState(false);
  const isMobile = useIsMobile();

  return (
    <>
      <Image
        src={imagePath}
        className="supplies_feed__post--image"
        thumbnail
        onClick={() => setShowModal(true)}
      />
      <Modal
        width={!isMobile ? '50' : '100'}
        title=""
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        footer={false}
      >
        <Image
          src={imagePath}
          fluid
          thumbnail
          style={{ width: '100%', margin: '0 auto', textAlign: 'center' }}
        />
      </Modal>
    </>
  );
};

SuppliesFeedPostImage.propTypes = {
  imagePath: PropTypes.string.isRequired,
};
export default SuppliesFeedPostImage;
