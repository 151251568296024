import React from 'react';
import MessagesNav from '../components/MessagesNav/MessagesNav';

/**
 * Navigation - used in components/Header/Header.js based on the
 * type of user logged in.
 *
 * You can either provide a name or if you need custom markup you
 * can provide 'component' instead which should be vaid JSX.
 * 'component' will take precedence if you have both fields in one
 * object.
 *
 * Also takes an 'id' which is assigned to the link as a HTML id
 */
export const clientNavigation = [
  {
    name: 'Post a Job',
    path: '/jobs/post',
  },
  {
    name: 'My Jobs',
    path: '/client/jobs',
  },
  {
    name: 'Supplies Feed',
    path: '/feed/supplies',
  },
];

export const operativeNavigation = id => [
  {
    type: 'dropdown',
    name: 'Jobs',
    items: [
      {
        name: 'Your Jobs',
        path: '/jobs/matched',
      },
      {
        name: 'Applied Jobs',
        path: '/jobs/applied',
      },
      { name: 'All Jobs', path: '/' },
    ],
  },

  {
    name: 'My Profile',
    path: `/operative/${id}/profile`,
  },
  {
    name: 'Supplies Feed',
    path: '/feed/supplies',
  },
];

export const supplierNavigation = id => [
  {
    name: 'Feed',
    path: '/feed/supplies',
  },
  {
    name: 'My Profile',
    path: `/supplier/${id}/profile`,
  },
];

export const accommodationNavigation = [
  {
    name: 'Create Accommodation Listing',
    path: '/accommodation/create',
  },
  {
    name: 'Your Listings',
    path: '/accommodation/posted',
  },
  {
    name: 'Settings',
    path: '/settings',
  },
];

export const globalNavigation = [
  {
    name: 'Accommodation',
    path: '/accommodation',
  },
  {
    name: 'Suppliers',
    path: '/suppliers',
  },
  {
    path: '/messages',
    component: <MessagesNav />,
  },
  {
    name: 'Settings',
    path: '/settings',
  },
];

export const landingPageNavigation = [
  // {
  //   name: 'Why Direct Labour Exchange?',
  //   path: '#why',
  // },
  {
    name: 'Labour Operative',
    path: '#operative',
  },
  {
    name: 'Construction Company',
    path: '#company',
  },
  {
    name: 'Supplier',
    path: '#supplier',
    id: 'supplier-link',
  },
  {
    name: 'Recruitment Agency',
    path: '#recruitment',
    id: 'recruitment-link',
  },
  {
    name: 'Property Owner',
    path: '#accommodation',
  },
];

export const adminNavigation = [
  {
    name: 'Admin Home',
    path: '/admin/home',
  },
];
