import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './JobCard.scss';

import Button from '../Reusable/Button';

const JobCard = ({
  id,
  jobTitle,
  placementType,
  jobStartDate,
  jobEndDate,
  payDetails,
  regions,
  occupations,
  jobCertifications,
  jobDescription,
  createdAt,
}) => (
  <Col xs="12" md="6" lg="4">
    <div className="job-card">
      <h4 className="job-card__title">{jobTitle}</h4>
      <p className="job-card__placement-type">
        {placementType.name} - {payDetails}
      </p>
      {jobStartDate && jobEndDate && (
        <p className="job-card__dates">
          {moment(jobStartDate).format('DD/MM/YYYY')} - {moment(jobEndDate).format('DD/MM/YYYY')}
        </p>
      )}
      <p className="job-card__location">{regions.map(region => region.name).join(', ')}</p>
      <p className="job-card__certification">
        <strong>Certs: </strong>
        {jobCertifications
          .slice(0, 2)
          .map(cert => cert.name)
          .join(', ')}
        <small>{jobCertifications.length > 2 && ` + ${jobCertifications.length - 2} more`}</small>
      </p>
      <p className="job-card__occupations">
        <strong>Trades: </strong>
        {occupations
          .slice(0, 2)
          .map(occ => occ.name)
          .join(', ')}
        <small>{occupations.length > 2 && ` + ${occupations.length - 2} more`}</small>
      </p>
      <span
        className="job-card__description"
        dangerouslySetInnerHTML={{ __html: jobDescription }}
      />
      <p className="job-card__created-at">Created on {moment(createdAt).format('DD/MM/YYYY')}</p>
      <Link to={`/jobs/${id}`}>
        <Button className="job-card__view-button">View Job</Button>
      </Link>
    </div>
  </Col>
);

JobCard.propTypes = {
  id: PropTypes.number.isRequired,
  jobTitle: PropTypes.string.isRequired,
  placementType: PropTypes.shape({ name: PropTypes.string }).isRequired,
  jobStartDate: PropTypes.string.isRequired,
  jobEndDate: PropTypes.string.isRequired,
  payDetails: PropTypes.string.isRequired,
  regions: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
  jobCertifications: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
  occupations: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
  jobDescription: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};
export default JobCard;
