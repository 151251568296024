import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const updateSupplierProfile = async (payload, id) => {
  const { data } = await postRequest(
    true,
    Endpoints.UPDATE_SUPPLIER_PROFILE,
    { ...payload },
    { id },
    'PUT',
  );
  return camelCaseKeys(data.data);
};

export default updateSupplierProfile;
