import 'react-dates/initialize';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import createMock from './mock';
import { AppContextProvider } from './AppContext';

if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
  createMock();
}

toast.configure({
  autoClose: 10000,
  top: '60px',
});

const render = Component =>
  // eslint-disable-next-line
  ReactDOM.render(
    <BrowserRouter basename="/">
      <AppContextProvider>
        <Component />
      </AppContextProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
