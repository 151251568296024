import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import './SuppliesFeed.scss';
import SuppliesFeedPostImage from './SuppliesFeedPostImage';
import Modal from '../../Modal/Modal';
import SendMessage from '../../Messages/SendMessage';
import CustomButton from '../../Reusable/Button';

const SuppliesFeedPost = ({ id, title, description, account, type, region, createdAt, images }) => {
  const [showMore, setShowMore] = useState(false);
  const [showModal, setShowModal] = useState(false);

  let name = 'Unknown';
  if (account && account.recruiter) {
    name = account.recruiter.companyName;
  }
  if (account && account.client) {
    name = account.client.companyName;
  }
  if (account && account.supplier) {
    name = account.supplier.companyName;
  }
  if (account && !account.recruiter && !account.client && !account.supplier) {
    name = `${account.firstName} ${account.lastName}`;
  }

  return (
    <div
      className={`supplies_feed__post ${type.name}`}
      style={
        {
          // borderTop: account.type.name === 'Supplier' ? '20px solid #f16e15' : '20px solid #274970',
        }
      }
      key={id}
    >
      <h2
        className="supplies_feed__post--type"
        style={{
          backgroundColor: account.type.name === 'Supplier' ? '#f16e15' : '#274970',
        }}
      >
        {' '}
        {account.type.name}{' '}
      </h2>{' '}
      <h3 className="supplies_feed__post--title"> {title} </h3>{' '}
      <h4 className="supplies_feed__post--account">
        {' '}
        {account.type.name === 'Supplier' ? (
          <>
            <Link to={`/supplier/${account.id}/profile`}>
              {' '}
              {account.recruiter && account.recruiter.companyName}{' '}
              {account.client && account.client.companyName}{' '}
              {account.supplier && account.supplier.companyName}{' '}
              {!account.recruiter && !account.client && !account.supplier && account.firstName}{' '}
              {!account.recruiter && !account.client && !account.supplier && account.lastName}{' '}
            </Link>{' '}
            - {region.name}{' '}
          </>
        ) : (
          <>{name} </>
        )}{' '}
      </h4>{' '}
      {Number(localStorage.getItem('account_id')) !== account.id && (
        <>
          <CustomButton onClick={() => setShowModal(true)} small>
            Send Message{' '}
          </CustomButton>{' '}
        </>
      )}{' '}
      <hr />
      <p className="supplies_feed__post--desc">
        {' '}
        {description.length > 150
          ? `${description.substring(0, showMore ? description.length : '150')}`
          : description}{' '}
        {description.length > 150 && !showMore && '...'}{' '}
        {description.length > 150 && (
          <Button variant="link p-0" onClick={() => setShowMore(!showMore)}>
            See {showMore ? 'Less' : 'More'}{' '}
          </Button>
        )}{' '}
      </p>{' '}
      {images && Boolean(images.length) && (
        <>
          <div className="supplies_feed__post--images-container">
            {' '}
            {images.map(image => (
              <SuppliesFeedPostImage {...image} />
            ))}{' '}
          </div>{' '}
        </>
      )}{' '}
      <hr />
      <h4 className="supplies_feed__post--date">
        {' '}
        {moment(createdAt).format('DD MMMM [at] kk:mm')}{' '}
      </h4>{' '}
      <Modal
        width="60"
        show={showModal}
        handleClose={() => setShowModal(false)}
        title={`Send ${name} a Message`}
      >
        <SendMessage
          id={account.id}
          onSend={() => setShowModal(false)}
          name={`RE: Feed Post '${title}'`}
        />{' '}
      </Modal>{' '}
    </div>
  );
};

SuppliesFeedPost.defaultProps = {
  images: null,
};
SuppliesFeedPost.propTypes = {
  title: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  account: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  type: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  region: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  images: PropTypes.objectOf(PropTypes.any),
};
export default SuppliesFeedPost;
