import React from 'react';
import { Navbar, Nav as BsNav } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import './Admin.scss';
import logout from '../../services/Logout';

export const handleLogoutAccount = async (e, history) => {
  e.preventDefault();
  await logout();

  localStorage.removeItem('token');
  localStorage.removeItem('account_id');
  localStorage.removeItem('account_type');

  history.push('/login');
};

const Nav = props => (
  <Navbar bg="dark" expand="lg" variant="dark" className="mb-3">
    <Navbar.Brand href="#">Admin</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <BsNav className="ml-auto">
        <BsNav.Link href="/admin/home">Home</BsNav.Link>
        <BsNav.Link href="/admin/members-list">List of Members</BsNav.Link>
        <BsNav.Link href="/admin/moderate-reviews">Reviews</BsNav.Link>
        <BsNav.Link href="/admin/accommodation/create">Create Accommodation</BsNav.Link>
        <BsNav.Link href="/admin/accommodation">Accommodations List</BsNav.Link>
        <BsNav.Link
          href="/admin/accommodation"
          onClick={e => handleLogoutAccount(e, props.history)}
        >
          Logout
        </BsNav.Link>
      </BsNav>
    </Navbar.Collapse>
  </Navbar>
);

export default withRouter(Nav);
