import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './CookieConsent.scss';

const CookieConsent = ({ setCookie }) => {
  const handleClick = () => {
    setCookie('cookie-consent', true, {
      path: '/',
      expires: new Date(moment().add('6', 'months')),
    });
  };
  return (
    <div className="cookie-consent">
      <small>
        We use cookies to ensure that we give you the best experience on our website. By navigating
        the site, you agree to the use of cookies on Direct Labour Exchange. Read our{' '}
        <Link to="/cookie-policy">Cookie Policy</Link> to learn more.
        <Button variant="outline-dark" onClick={handleClick}>
          Okay
        </Button>
      </small>
      <Button variant="link" onClick={handleClick}>
        <i className="fas fa-times" />
      </Button>
    </div>
  );
};

CookieConsent.propTypes = {
  setCookie: PropTypes.func.isRequired,
};

export default CookieConsent;
