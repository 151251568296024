import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getPostedJobs = async page => {
  const { data } = await getRequest(true, Endpoints.GET_POSTED_JOBS, { page });
  return camelCaseKeys(data);
};

export default getPostedJobs;
