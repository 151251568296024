import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Alert, Container } from 'react-bootstrap';
import { union } from 'lodash';
import PropTypes from 'prop-types';

import './PostJob.scss';

import Loader from '../Loader/Loader';
import PostJobForm from '../PostJobForm/PostJobForm';

import {
  editJob,
  getJob,
  getRegions as getRegionsService,
  getJobPlacementTypes as getJobPlacementTypesService,
  getCertifications as getCertificationsService,
  getOccupations as getOccupationsService,
  postJob,
} from '../../services';

const PostJob = ({ edit, match }) => {
  const [regions, setRegions] = useState([]);
  const [placementTypes, setPlacementTypes] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [redirect, setRedirect] = useState(null);
  const [job, setJob] = useState(null);

  useEffect(() => {
    const getRegions = async () => {
      const data = await getRegionsService();
      setRegions(data);
    };
    const getPlacementTypes = async () => {
      const data = await getJobPlacementTypesService();
      setPlacementTypes(data);
    };
    const getCertifications = async () => {
      const data = await getCertificationsService();
      setCertifications(data);
    };
    const getOccupations = async () => {
      const data = await getOccupationsService();
      setOccupations(data);
    };

    getRegions();
    getPlacementTypes();
    getCertifications();
    getOccupations();

    const fetchJob = async () => {
      const res = await getJob(match.params.id);
      setJob(res);
    };

    if (edit) {
      fetchJob();
    }
  }, []);

  if (redirect) {
    return <Redirect to={redirect} push />;
  }

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitErrors([]);
    const payload = {
      ...values,
      common_region_id: parseInt(values.region, 10),
      certifications: values.certification,
      job_placement_type_id: parseInt(values.placement_type, 10),
      accommodation_included: values.accommodation_included ? 1 : 0,
      loyalty_bonus_optin: values.loyalty_bonus_optin ? 1 : 0,
    };

    try {
      if (edit) {
        await editJob(payload, match.params.id);
      } else {
        await postJob(payload);
      }
      setRedirect('/client/jobs');
    } catch (e) {
      setSubmitErrors(union(submitErrors, [e.message]));
      window.scrollTo(0, 0);
      setSubmitting(false);
    }
  };

  if (!regions.length || !placementTypes.length || !certifications.length || !occupations.length) {
    return <Loader />;
  }

  if (edit && !job) {
    return <Loader />;
  }

  return (
    <Container className="post-job" fluid>
      <h2 className="post-job__title">Post a Job</h2>
      <div className="post-job__inner">
        {Boolean(submitErrors.length) && (
          <Alert variant="danger" className="mt-3">
            <h4>There was an issue creating your job:</h4>
            <ul>
              {submitErrors.map(error => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </Alert>
        )}
        <PostJobForm
          regions={regions}
          onSubmit={onSubmit}
          placementTypes={placementTypes}
          certifications={certifications}
          occupations={occupations}
          job={job}
        />
      </div>
    </Container>
  );
};

PostJob.defaultProps = {
  edit: false,
  match: {},
};
PostJob.propTypes = {
  edit: PropTypes.bool,
  match: PropTypes.objectOf(PropTypes.any),
};
export default withRouter(PostJob);
