import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import io from 'socket.io-client';

import { getUnreadMessageCount } from '../../services';

const socket = io.connect(process.env.REACT_APP_API_URL);

const MessagesNav = ({ location }) => {
  const [count, setCount] = useState(null);

  const getCount = async () => {
    const data = await getUnreadMessageCount();
    setCount(data);
  };

  useEffect(() => {
    getCount();
  }, [location]);

  useEffect(() => {
    socket.on(`unread_count_${localStorage.getItem('account_id')}`, data => {
      setCount(data);
    });

    return () => socket.close();
  }, []);

  return <>Messages ({count && `${count}`})</>;
};

MessagesNav.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withRouter(MessagesNav);
