import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const updateCertificationFiles = async (payload, id) => {
  const { data } = await postRequest(
    true,
    Endpoints.UPDATE_OPERATIVE_CERTIFICATION_FILES,
    payload,
    { id },
    'PUT',
    null,
  );
  return data.data;
};

export default updateCertificationFiles;
