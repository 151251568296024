import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getSingleAccommodation = async id => {
  const { data } = await getRequest(true, Endpoints.GET_SINGLE_ACCOMMODATION, {}, { id });
  return camelCaseKeys(data.data);
};

export default getSingleAccommodation;
