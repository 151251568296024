import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const deleteJob = async id => {
  const { data } = await postRequest(true, Endpoints.DELETE_JOB, {}, { id }, 'DELETE');

  return camelCaseKeys(data);
};

export default deleteJob;
