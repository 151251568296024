import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../../constants';
import { postRequest } from '../../helpers/requestHelpers';

const deletePost = async id => {
  const { data } = await postRequest(
    true,
    Endpoints.DELETE_SUPPLIES_FEED_POST,
    {},
    { id },
    'DELETE',
  );
  return camelCaseKeys(data);
};

export default deletePost;
