import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Alert, Container } from 'react-bootstrap';
import { union } from 'lodash';

import Loader from '../Loader/Loader';
import RegisterOperativeForm from '../RegisterOperativeForm/RegisterOperativeForm';
import {
  getRegions,
  registerOperative,
  login as loginService,
  doesAccountExist,
  getCertifications,
  getOccupations,
} from '../../services';
import { AccountTypes } from '../../constants';

const RegisterOperative = ({ comingSoon }) => {
  const [regions, setRegions] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [redirect, setRedirect] = useState(null);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    setRedirect(null);
    const getData = async () => {
      const data = await Promise.all([getRegions(), getCertifications(), getOccupations()]);
      setRegions(data[0]);
      setCertifications(data[1]);
      setOccupations(data[2]);
    };

    getData();
  }, [redirect]);

  if (redirect) {
    return <Redirect to={redirect} push />;
  }

  if (success) {
    return (
      <>
        <h4>Thank you. You have successfully registered, you may now close this page</h4>
      </>
    );
  }

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitErrors([]);
    const payload = {
      ...values,
      common_region_id: parseInt(values.region, 10),
    };

    try {
      // if (!values.recaptcha) {
      //   throw new Error('Please complete the recaptcha');
      // }

      const result = await doesAccountExist(values.email_address, 1);
      if (result.doesExist) {
        throw new Error(
          'There is already account registered with this email address, please login or use a different email address instead',
        );
      }
      await registerOperative(payload);
      const loginAttempt = await loginService(
        values.email_address,
        values.password,
        AccountTypes.OPERATIVE.id,
      );
      localStorage.setItem('token', loginAttempt.data.token);
      localStorage.setItem('account_id', loginAttempt.data.id);
      localStorage.setItem('account_type', AccountTypes.OPERATIVE.id);
      if (!comingSoon) {
        setRedirect('/');
      } else {
        setRedirect(null);
        setSuccess(true);
      }
    } catch (e) {
      setSubmitErrors(union(submitErrors, [e.message]));
      window.scrollTo(0, 0);
      setSubmitting(false);
    }
  };

  if (!regions.length || !certifications.length || !occupations.length) {
    return <Loader />;
  }

  return (
    <Container fluid>
      {Boolean(submitErrors.length) && (
        <Alert variant="danger" className="mt-3">
          <h4>There was an issue creating your account:</h4>
          <ul>
            {submitErrors.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Alert>
      )}
      <RegisterOperativeForm
        regions={regions}
        certifications={certifications}
        occupations={occupations}
        onSubmit={onSubmit}
      />
    </Container>
  );
};

RegisterOperative.defaultProps = {
  comingSoon: false,
};

RegisterOperative.propTypes = {
  comingSoon: PropTypes.bool,
};

export default RegisterOperative;
