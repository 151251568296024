import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getJobPlacementTypes = async () => {
  const { data } = await getRequest(false, Endpoints.GET_JOB_PLACEMENT_TYPES);
  return camelCaseKeys(data.data);
};

export default getJobPlacementTypes;
