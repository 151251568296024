import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Button = ({ children, primary, type, variant, outline, small, ...props }) => {
  const { className } = props;
  return (
    // eslint-disable-next-line
    <button
      {...props}
      type={type}
      className={`custom-button ${primary && 'primary'} ${outline && 'outline'} ${className || ''}`}
      style={{
        borderRadius: variant === 'rounded' ? '100px' : '0',
        minWidth: small && '200px',
        width: small && 'auto',
        paddingTop: small && '6px',
        paddingBottom: small && '6px',
      }}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  primary: true,
  type: 'button',
  variant: 'rounded',
  outline: false,
  className: '',
  small: false,
};
Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]).isRequired,
  primary: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.string,
  outline: PropTypes.bool,
  className: PropTypes.string,
  small: PropTypes.bool,
};

export default Button;
