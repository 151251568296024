import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { getRequest } from '../helpers/requestHelpers';

const getConversation = async (id, page = 1) => {
  const { data } = await getRequest(true, Endpoints.GET_CONVERSATION, { page }, { id });
  return camelCaseKeys(data.data);
};

export default getConversation;
