import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const updateCv = async (payload, id) => {
  const { data } = await postRequest(
    true,
    Endpoints.UDPATE_OPERATIVE_PROFILE_CV,
    payload,
    { id },
    'PUT',
    null,
  );
  return data.data;
};

export default updateCv;
