export const accountTypes = JSON.stringify({
  data: [
    {
      id: 1,
      name: 'Client',
    },
    {
      id: 2,
      name: 'Company',
    },
    {
      id: 3,
      name: 'Operative',
    },
  ],
});
