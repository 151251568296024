import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const editJob = async (payload, id) => {
  const { data } = await postRequest(true, Endpoints.EDIT_JOB, payload, { id }, 'PUT');
  return camelCaseKeys(data.data);
};

export default editJob;
