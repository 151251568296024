const { REACT_APP_API_URL } = process.env;
const Endpoints = {
  LOGIN: `${REACT_APP_API_URL}/authentication/login`,
  LOGOUT: `${REACT_APP_API_URL}/authentication/logout`,
  GET_ACCOUNT_TYPES: `${REACT_APP_API_URL}/account/account-types`,
  CHECK_PAGE_PERMISSIONS: `${REACT_APP_API_URL}/account-type-check`,
  GET_REGIONS: `${REACT_APP_API_URL}/common/regions`,
  GET_CERTIFICATIONS: `${REACT_APP_API_URL}/common/certifications`,
  POST_REGISTER_CLIENT: `${REACT_APP_API_URL}/account/register/client`,
  POST_REGISTER_RECRUITER: `${REACT_APP_API_URL}/account/register/recruiter`,
  DOES_ACCOUNT_EXIST: `${REACT_APP_API_URL}/account/does-exist`,
  POST_REGISTER_OPERATIVE: `${REACT_APP_API_URL}/account/register/operative`,
  GET_JOB_PLACEMENT_TYPES: `${REACT_APP_API_URL}/common/job-placement-types`,
  POST_JOB: `${REACT_APP_API_URL}/job`,
  GET_POSTED_JOBS: `${REACT_APP_API_URL}/account/client/jobs`,
  GET_MY_MESSAGES: `${REACT_APP_API_URL}/conversation`,
  GET_UNREAD_MESSAGE_COUNT: `${REACT_APP_API_URL}/conversation/unread-count`,
  GET_OCCUPATIONS: `${REACT_APP_API_URL}/common/occupations`,
  POST_REGISTER_SUPPLIER: `${REACT_APP_API_URL}/account/register/supplier`,
  ADMIN_GET_MEMBERS: `${REACT_APP_API_URL}/admin/members-list`,
  ADMIN_GET_MEMBER: `${REACT_APP_API_URL}/admin/member/$id`,
  GET_PAGES: `${REACT_APP_API_URL}/common/pages`,
  GET_JOBS_FEED: `${REACT_APP_API_URL}/feed/jobs`,
  GET_SUPPLIES_FEED: `${REACT_APP_API_URL}/feed/supplies`,
  GET_SUPPLIES_FEED_POST: `${REACT_APP_API_URL}/feed/supplies/$id`,
  DELETE_SUPPLIES_FEED_POST: `${REACT_APP_API_URL}/feed/supplies/$id`,
  RESET_PASSWORD_REQUEST: `${REACT_APP_API_URL}/authentication/reset-password`,
  GET_JOB: `${REACT_APP_API_URL}/job/$id`,
  APPLY_JOB: `${REACT_APP_API_URL}/job/$id/apply`,
  APPLIED_JOBS: `${REACT_APP_API_URL}/account/operative/applied-jobs`,
  GET_ACCOUNT: `${REACT_APP_API_URL}/account`,
  UPDATE_COMMUNICATION_PREFERENCES: `${REACT_APP_API_URL}/account/communication-preferences`,
  GET_OPERATIVE_PROFILE: `${REACT_APP_API_URL}/account/operative/$id`,
  UDPATE_OPERATIVE_PROFILE: `${REACT_APP_API_URL}/account/operative/$id`,
  UDPATE_OPERATIVE_PROFILE_PICTURE: `${REACT_APP_API_URL}/account/operative/$id/profile-picture`,
  UDPATE_OPERATIVE_PROFILE_CV: `${REACT_APP_API_URL}/account/operative/$id/cv`,
  DELETE_OPERATIVE_PROFILE_CV: `${REACT_APP_API_URL}/account/operative/$id/cv/remove`,
  DELETE_OPERATIVE_PROFILE_CERTIFICATION_FILES: `${REACT_APP_API_URL}/account/operative/$id/certification-files/remove`,
  UPDATE_OPERATIVE_CERTIFICATION_FILES: `${REACT_APP_API_URL}/account/operative/$id/certification-files`,
  GET_CONVERSATIONS: `${REACT_APP_API_URL}/conversation`,
  GET_CONVERSATION: `${REACT_APP_API_URL}/conversation/$id`,
  SEND_MESSAGE: `${REACT_APP_API_URL}/conversation/$id/send`,
  EDIT_JOB: `${REACT_APP_API_URL}/job/$id`,
  GET_APPLICANTS: `${REACT_APP_API_URL}/job/$id/applicants`,
  CAN_VIEW_PROFILE: `${REACT_APP_API_URL}/account/operative/$id/profile/can-view`,
  ACCEPT_APPLICANT: `${REACT_APP_API_URL}/job/$jobId/applicants/$applicantId/accept`,
  REJECT_APPLICANT: `${REACT_APP_API_URL}/job/$jobId/applicants/$applicantId/reject`,
  CREATE_CONVERSATION: `${REACT_APP_API_URL}/conversation`,
  CREATE_SUPPLY_POST: `${REACT_APP_API_URL}/feed/supplies`,
  VALIDATE_RESET_PASSWORD_REQUEST: `${REACT_APP_API_URL}/authentication/reset-password/validate`,
  CHANGE_PASSWORD: `${REACT_APP_API_URL}/authentication/change-password`,
  CHECK_FEEDBACK_TOKEN_VALID: `${REACT_APP_API_URL}/feedback/check-token`,
  UPDATE_FEEDBACK: `${REACT_APP_API_URL}/feedback/$id`,
  GET_FEEDBACK: `${REACT_APP_API_URL}/feedback`,
  GET_SINGLE_FEEDBACK: `${REACT_APP_API_URL}/feedback/$id`,
  APPROVE_FEEDBACK: `${REACT_APP_API_URL}/feedback/approve`,
  REJECT_FEEDBACK: `${REACT_APP_API_URL}/feedback/reject`,
  EMAIL_UNSUBSCRIBE: `${REACT_APP_API_URL}/email/unsubscribe`,
  GET_MATCHED_JOBS: `${REACT_APP_API_URL}/account/operative/matched-jobs`,
  GET_SUPPLIER_PROFILE: `${REACT_APP_API_URL}/account/supplier/$id`,
  UPDATE_SUPPLIER_PROFILE: `${REACT_APP_API_URL}/account/supplier/$id`,
  UPDATE_SUPPLIER_PROFILE_IMAGE: `${REACT_APP_API_URL}/account/supplier/$id/profile-picture`,
  GET_ACCOMMODATIONS: `${REACT_APP_API_URL}/accommodation`,
  CREATE_ACCOMMODATION: `${REACT_APP_API_URL}/accommodation`,
  EDIT_ACCOMMODATION: `${REACT_APP_API_URL}/accommodation/$id`,
  GET_SINGLE_ACCOMMODATION: `${REACT_APP_API_URL}/accommodation/$id`,
  GET_CITIES: `${REACT_APP_API_URL}/common/cities`,
  GET_COMPANY_DETAILS: `${REACT_APP_API_URL}/common/company-details`,
  UPDATE_COMPANY_DETAILS: `${REACT_APP_API_URL}/common/company-details`,
  DELETE_JOB: `${REACT_APP_API_URL}/job/$id`,
  ALL_SUPPLIERS: `${REACT_APP_API_URL}/account/supplier/all`,
  GDPR_ERASE: `${REACT_APP_API_URL}/account/$id/delete`,
  GDPR_ALL_DATA: `${REACT_APP_API_URL}/account/$id/gdpr`,
  DEACTIVATE_ACCOUNT: `${REACT_APP_API_URL}/account/$id/deactivate`,
  ACTIVATE_ACCOUNT: `${REACT_APP_API_URL}/account/$id/activate`,
  CREATE_ACCOMMODATION_ACCOUNT: `${REACT_APP_API_URL}/account/register/accommodation`,
  GET_POSTED_ACCOMMODATION: `${REACT_APP_API_URL}/accommodation/posted`,
  DELETE_ACCOMMODATION: `${REACT_APP_API_URL}/accommodation/$id`,

  // Stripe
  CREATE_PAYMENT_INTENT: `${REACT_APP_API_URL}/stripe/payment-intent`,
  GET_PRICING: `${REACT_APP_API_URL}/common/pricing`,

  // Transactions
  CREATE_TRANSACTION: `${REACT_APP_API_URL}/transaction`,

  GET_SUPPLIER_CATEGORIES: `${REACT_APP_API_URL}/supplier/categories`,
  ASSIGN_SUPPLIER_TO_CATEGORIES: `${REACT_APP_API_URL}/supplier/categories/assign`,
  COMMON_SUPPLIER_CATEGORIES: `${REACT_APP_API_URL}/common/supplier-categories`,
};

export default Endpoints;
