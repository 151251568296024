// import { pathToRegexp } from 'path-to-regexp';

import AccountTypes from './accountTypes';

const { pathToRegexp } = require('path-to-regexp');

const Pages = {
  ROOT: {
    path: '/',
    authenticated: false,
  },
  LOGIN: {
    path: '/login',
    authenticated: false,
  },
  HOME: {
    path: '/home',
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ACCOMMODATION.id]: true,
    },
  },
  FEED: {
    path: '/feed',
    authenticated: true,
    hasAccess: {
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.COMPANY.id]: false,
    },
  },
  FEED_JOBS: {
    path: '/feed/jobs',
    authenticated: true,
    hasAccess: {
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
    },
  },
  FEED_SUPPLIES: {
    path: '/feed/supplies',
    authenticated: true,
    hasAccess: {
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },
  REGISTER_CLIENT: {
    path: '/register/client',
    authenticated: false,
  },
  REGISTER_OPERATIVE: {
    path: '/register/operative',
    authenticated: false,
  },
  REGISTER_SUPPLIER: {
    path: '/register/supplier',
    authenticated: false,
  },
  REGISTER_RECRUITER: {
    path: '/register/recruiter',
    authenticated: false,
  },
  REGISTER_ACCOMMODATION: {
    path: '/register/accommodation',
    authenticated: false,
  },
  POST_JOB: {
    path: '/jobs/post',
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
    },
  },

  POSTED_JOBS: {
    path: '/client/jobs',
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: false,
    },
  },
  COMING_SOON: {
    path: '/coming-soon',
    authenticated: false,
  },
  FORBIDDEN: {
    path: '/forbidden',
    authenticated: false,
  },
  ADMIN_HOME: {
    path: '/admin/home',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },
  ADMIN_MEMBERS_LIST: {
    path: '/admin/members-list',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },
  ADMIN_SUPPLIERS_LIST: {
    path: '/admin/suppliers',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },

  TERMS_AND_CONDITIONS: {
    path: '/terms-and-conditions',
    authenticated: false,
  },
  COOKIE_POLICY: {
    path: '/cookie-policy',
    authenticated: false,
  },
  ABOUT: {
    path: '/about',
    authenticated: false,
  },

  FORGOT_PASSWORD: {
    path: '/forgot-password',
    authenticated: false,
  },
  SIGN_UP: {
    path: '/signup',
    authenticated: false,
  },

  APPLIED_JOBS: {
    path: '/jobs/applied',
    authenticated: true,
    hasAccess: {
      [AccountTypes.OPERATIVE.id]: true,
    },
  },
  MATCHED_JOBS: {
    path: '/jobs/matched',
    authenticated: true,
    hasAccess: {
      [AccountTypes.OPERATIVE.id]: true,
    },
  },
  SETTINGS: {
    path: '/settings',
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ACCOMMODATION.id]: true,
    },
  },

  MESSAGES: {
    path: '/messages',
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },

  RESET_PASSWORD: {
    path: '/password-reset',
    authenticated: false,
  },
  LEAVE_REVIEW: {
    path: '/leave-review',
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },
  ADMIN_MODERATE_FEEDBACK: {
    path: '/admin/moderate-reviews',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },

  UNSUBSCRIBE: {
    path: '/unsubscribe',
    authenticated: false,
  },

  ADMIN_ACCOMMODATION_LIST: {
    path: '/admin/accommodation',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },
  ADMIN_ACCOMMODATION_CREATE: {
    path: '/admin/accommodation/create',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },

  ACCOMMODATION: {
    path: '/accommodation',
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },
  ADMIN_JOBS_LIST: {
    path: '/admin/jobs-list',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },

  SUPPLIERS: {
    path: '/suppliers',
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },
  SUPPLIERS_BY_CATEGORY: {
    path: '/suppliers/category/:id',
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },
  CREATE_ACCOMMODATION: {
    path: '/accommodation/create',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ACCOMMODATION.id]: true,
    },
  },
  POSTED_ACCOMMODATION: {
    path: '/accommodation/posted',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ACCOMMODATION.id]: true,
    },
  },

  ACCOMMODATION_PAYMENT: {
    path: '/accommodation/payment',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ACCOMMODATION.id]: true,
    },
  },
  SUPPLIER_PAYMENT: {
    path: '/supplier/payment',
    authenticated: true,
    hasAccess: {
      [AccountTypes.SUPPLIER.id]: true,
    },
  },
  ADMIN_ACCOMMODATION_EDIT: {
    path: () => pathToRegexp('/admin/accommodation/:id/edit'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },
  SUPPLIER_PROFILE: {
    path: () => pathToRegexp('/supplier/:id/profile'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },
  ADMIN_MODERATE_SINGLE_FEEDBACK: {
    path: () => pathToRegexp('/admin/feedback/:id'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },
  SUPPLIER_PROFILE_EDIT: {
    path: () => pathToRegexp('/supplier/:id/profile/edit'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },
  JOB: {
    path: () => pathToRegexp('/jobs/:id'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.OPERATIVE.id]: true,
    },
  },
  EDIT_JOB: {
    path: () => pathToRegexp('/jobs/:id/edit'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
    },
  },
  ADMIN_MEMBER: {
    path: () => pathToRegexp('/admin/member/:id'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },
  EDIT_ACCOMMODATION: {
    path: () => pathToRegexp('/accommodation/:id/edit'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.ACCOMMODATION.id]: true,
    },
  },
  ADMIN_JOB: {
    path: () => pathToRegexp('/admin/job/:id'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },
  APPLY_JOB: {
    path: () => pathToRegexp('/jobs/:id/apply'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.OPERATIVE.id]: true,
    },
  },
  OPERATIVE_PROFILE: {
    path: () => pathToRegexp('/operative/:id/profile'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },
  OPERATIVE_PROFILE_EDIT: {
    path: () => pathToRegexp('/operative/:id/profile/edit'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
      [AccountTypes.ADMIN.id]: true,
    },
  },
  CONVERSATION: {
    path: () => pathToRegexp('/conversation/:id'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
      [AccountTypes.OPERATIVE.id]: true,
      [AccountTypes.SUPPLIER.id]: true,
    },
  },
  JOB_APPLICANTS: {
    path: () => pathToRegexp('/jobs/:id/applicants'),
    authenticated: true,
    hasAccess: {
      [AccountTypes.COMPANY.id]: true,
      [AccountTypes.RECRUITER.id]: true,
    },
  },
  ADMIN_FEED: {
    path: '/admin/feed',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },
  ADMIN_FEED_POST: {
    path: '/admin/feed/:id',
    authenticated: true,
    hasAccess: {
      [AccountTypes.ADMIN.id]: true,
    },
  },
};

export default Pages;
