import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../../constants';
import { postRequest } from '../../helpers/requestHelpers';

const createTransaction = async (type, id = null) => {
  const { data } = await postRequest(true, Endpoints.CREATE_TRANSACTION, {
    type,
    id,
  });

  return camelCaseKeys(data);
};

export default createTransaction;
