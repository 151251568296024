// import produce from 'immer';

export const DATA_FETCHING_TABLE = 'DATA_FETCHING_TABLE';
export const DATA_FETCHING_FILTER = 'DATA_FETCHING_FILTER';
export const DATA_FETCHING_SEARCH = 'DATA_FETCHING_SEARCH';

const initialState = {
  test: true,
};

const appReducer = (state = initialState, action = {}) => ({ state, action });
// produce(state, draftState => {
//   switch (action.type) {
//     default:
//       break;
//   }
// });

export default appReducer;
