import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import createConversation from '../../services/CreateConversation';
import Button from '../Reusable/Button';

const SendMessage = ({ id, name, onSend }) => {
  const [message, setMessage] = useState('');

  const send = async () => {
    const res = await createConversation(
      [id, Number(localStorage.getItem('account_id'))],
      message,
      name,
    );
    toast.success('Message sent');
    onSend(res.data);
  };

  return (
    <>
      <Form.Group controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows="3" onChange={e => setMessage(e.target.value)} />
      </Form.Group>
      <Button onClick={send}>Send</Button>
    </>
  );
};

SendMessage.defaultProps = {
  name: null,
  onSend: () => null,
};
SendMessage.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  onSend: PropTypes.func,
};
export default SendMessage;
