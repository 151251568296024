import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Tabs, Tab } from 'react-bootstrap';

import './PostJobForm.scss';

import { validationSchema, getInitialValues } from './helpers';
import AdditionalDetails from './AdditionalDetails';
import BasicInfo from './BasicInfo';

const PostJobForm = ({ onSubmit, regions, placementTypes, certifications, occupations, job }) => {
  const [key, setKey] = useState('basicInfo');

  const options = certifications.map(c => ({ value: c.id, label: c.name }));
  const [certOptions, setCertOptions] = useState(options);

  const occOptions = occupations.map(o => ({ value: o.id, label: o.name }));
  const [occupationOptions, setOccupationOptions] = useState(occOptions);

  const switchTab = async (k, direction, fields, validateForm) => {
    if (direction === 'back') {
      setKey(k);
      return true;
    }
    const errors = await validateForm();
    if (Object.keys(errors).length) {
      const hasError = Object.keys(errors).map(val => {
        if (fields.includes(val)) {
          return true;
        }
        return false;
      });
      if (hasError.some(Boolean)) {
        // Has errors
        return false;
      }
      setKey(k);
      return true;
    }
    setKey(k);

    return true;
  };

  return (
    <Formik
      initialValues={getInitialValues(job)}
      validationSchema={yup.object().shape(validationSchema)}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        touched,
        validateForm,
        setFieldTouched,
        setFieldValue,
      }) => {
        const errorArray = errors;
        const basicInfoErrors = [
          'title',
          'description',
          'placement_type',
          'certification',
          'occupations',
        ];
        const additionalDetailsErrors = [
          'pay_details',
          'region',
          'additional_location_details',
          'accommodation_included',
          'loyalty_bonus_optin',
        ];

        if (Number(values.placement_type) === 1) {
          additionalDetailsErrors.push('start_date', 'end_date');
          if (errorArray.start_date) {
            delete errorArray.start_date;
          }
          if (errorArray.end_date) {
            delete errorArray.end_date;
          }
        }

        return (
          <Form onSubmit={handleSubmit} className="post-job-form">
            <Tabs activeKey={key}>
              <Tab eventKey="basicInfo" title={<h1>Basic Information</h1>}>
                <BasicInfo
                  placementTypes={placementTypes}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errorArray}
                  touched={touched}
                  certifications={certifications}
                  occupations={occupations}
                  validateForm={validateForm}
                  basicInfoErrors={basicInfoErrors}
                  next={switchTab}
                  setFieldTouched={setFieldTouched}
                  certOptions={certOptions}
                  setCertOptions={setCertOptions}
                  occupationOptions={occupationOptions}
                  setOccupationOptions={setOccupationOptions}
                  setFieldValue={setFieldValue}
                />
              </Tab>
              <Tab eventKey="dateAndPay" title={<h1>Date and Pay Details</h1>}>
                <AdditionalDetails
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errorArray}
                  touched={touched}
                  validateForm={validateForm}
                  next={switchTab}
                  back={() => switchTab('basicInfo', 'back')}
                  regions={regions}
                  additionalInfoErrors={additionalDetailsErrors}
                  isSubmitting={isSubmitting}
                  inEdit={job}
                />
              </Tab>
            </Tabs>
          </Form>
        );
      }}
    />
  );
};

PostJobForm.defaultProps = {
  job: null,
};
PostJobForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  placementTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  occupations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  job: PropTypes.objectOf(PropTypes.any),
};

export default PostJobForm;
