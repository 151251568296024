import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const emailUnsubscribe = async email => {
  const { data } = await postRequest(false, Endpoints.EMAIL_UNSUBSCRIBE, { email_address: email });
  return camelCaseKeys(data.data);
};

export default emailUnsubscribe;
