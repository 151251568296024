import camelCaseKeys from 'camelcase-keys-recursive';

import { Endpoints } from '../constants';
import { postRequest } from '../helpers/requestHelpers';

const sendMessage = async (message, id) => {
  const { data } = await postRequest(
    true,
    Endpoints.SEND_MESSAGE,
    {
      message,
    },
    { id },
  );
  return camelCaseKeys(data.data);
};

export default sendMessage;
