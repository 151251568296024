import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import './Login.scss';

import LoginForm from '../LoginForm/LoginForm';
import { login, getAccountTypes } from '../../services';
import { Error } from '../Error';
import { Loader } from '../Loader';
import { AccountTypes } from '../../constants';

const profileIcon = require('../../images/icon-profile.png');

const Login = ({ admin, location }) => {
  const [redirect, setRedirect] = useState(null);
  const [error, setError] = useState(null);
  const [accountTypes, setAccountTypes] = useState(null);
  const [redirectQueryString, setRedirectQueryString] = useState(null);

  useEffect(() => {
    const fetchAccountTypes = async () => {
      const types = await getAccountTypes();
      setAccountTypes(types.filter(type => type.name !== 'Admin'));
      return types;
    };

    fetchAccountTypes();

    const values = queryString.parse(location.search);
    if (values && values.redirect) {
      const redirectQs = `/${values.redirect.replace(/_/g, '/')}`;
      setRedirectQueryString(redirectQs);
      delete values.redirect;
      if (localStorage.getItem('token')) {
        setRedirect(`${redirectQs}?${queryString.stringify(values)}`);
      }
    }
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const accountType = admin ? AccountTypes.ADMIN.id : values.account_type;
      const loginAttempt = await login(values.email_address, values.password, accountType);

      localStorage.setItem('token', loginAttempt.data.token);
      localStorage.setItem('account_id', loginAttempt.data.id);
      localStorage.setItem('account_type', accountType);

      if (!redirectQueryString) {
        switch (parseInt(accountType, 10)) {
          case AccountTypes.COMPANY.id:
            setRedirect('/');
            break;
          case AccountTypes.OPERATIVE.id:
            setRedirect('/feed/jobs');
            break;
          case AccountTypes.SUPPLIER.id:
            setRedirect('/feed/supplies');
            break;
          case AccountTypes.ADMIN.id:
            setRedirect('/admin/home');
            break;
          case AccountTypes.RECRUITER.id:
            setRedirect('/');
            break;
          case AccountTypes.ACCOMMODATION.id:
            setRedirect('/');
            break;
          default:
            setRedirect('/');
            break;
        }
      } else {
        setRedirect(redirectQueryString);
      }
    } catch (e) {
      let message;
      switch (e.response.status) {
        case 400:
          message = 'A required field was not completed';
          break;
        case 403:
          message = 'The password you entered is incorrect';
          break;
        case 404:
          message =
            'An account with the email address or account type you entered could not be found';
          break;
        case 500:
          message = 'An unknown error has occured, please try again later';
          break;
        default:
          message = 'An unknown error has occured, please try again later';
          break;
      }
      setError({
        message,
        code: parseInt(e.response.status, 10),
      });
      setSubmitting(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} push />;
  }

  return (
    <div className="login background">
      <div className="overlay" />
      <div className="content centered">
        <div className="text-center">
          <img src={profileIcon} alt="Profile Icon" className="login__title-icon" />
        </div>
        <h2 className="login__title">{admin ? 'Admin Login' : 'Account Login'}</h2>
        {error && <Error message={error.message} code={error.code} />}
        {!accountTypes ? (
          <Loader />
        ) : (
          <LoginForm
            onSubmit={handleSubmit}
            accountTypes={accountTypes}
            admin={admin}
            setError={setError}
          />
        )}
      </div>
    </div>
  );
};

Login.defaultProps = {
  admin: false,
};
Login.propTypes = {
  admin: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export { Login };
export default withRouter(Login);
