import React from 'react';
import { Modal as BsModal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Modal.scss';
import useIsMobile from '../../hooks/useIsMobile';

const Modal = props => {
  const isMobile = useIsMobile();

  const { handleClose, title, children, footer, width, closeButton, show, ...rest } = props;

  const headerStyle = {};

  if (!title) {
    headerStyle.padding = 0;
    headerStyle.border = 'none';
  }

  return (
    <BsModal
      show={show}
      onHide={() => handleClose(false)}
      dialogClassName={`modal-${isMobile ? '100' : width}w`}
      {...rest}
    >
      <BsModal.Header closeButton>{title && <BsModal.Title>{title}</BsModal.Title>}</BsModal.Header>
      <BsModal.Body>{children}</BsModal.Body>
    </BsModal>
  );
};

Modal.defaultProps = {
  title: '',
  footer: false,
  width: 30,
  closeButton: true,
};

Modal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.any),
  ]).isRequired,
  footer: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  closeButton: PropTypes.bool,
  show: PropTypes.bool.isRequired,
};

export default Modal;
