import Axios from 'axios';

export const getRequest = async (
  authenticated = false,
  url,
  queryStringParams = {},
  params = {},
) => {
  try {
    const headers = {
      Referrer: window.location.href,
    };

    if (localStorage.getItem('account_type')) {
      headers.AccountType = localStorage.getItem('account_type');
    }

    let requestUrl = url;

    if (url.includes('$')) {
      const paramKeys = Object.keys(params);
      paramKeys.forEach(param => {
        requestUrl = url.replace(`$${param}`, params[param]);
      });
    }

    if (authenticated) {
      headers.Authorization = `JWT ${localStorage.getItem('token')}`;
    }

    return await Axios({
      method: 'get',
      url: requestUrl,
      params: queryStringParams,
      headers,
    });
  } catch (error) {
    console.log(error.response.data, error.status);

    if (error?.response?.data === 'Unauthorized' && error?.response.status === 401) {
      localStorage.clear();
      throw error;
    }

    throw error;
  }
};

export const postRequest = async (
  authenticated = false,
  url,
  body = {},
  params = {},
  method = 'POST',
  encoding = 'URL',
  additionalHeaders = {},
) => {
  try {
    const headers = {
      Referrer: window.location.href,
    };

    if (localStorage.getItem('account_type')) {
      headers.AccountType = localStorage.getItem('account_type');
    }

    let requestUrl = url;

    if (url.includes('$')) {
      const paramKeys = Object.keys(params);
      paramKeys.forEach(param => {
        requestUrl = requestUrl.replace(`$${param}`, params[param]);
      });
    }

    if (authenticated) {
      headers.Authorization = `JWT ${localStorage.getItem('token')}`;
    }

    if (encoding === 'url') {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    const finalHeaders = {
      ...headers,
      ...additionalHeaders,
    };

    return await Axios({
      method,
      headers: finalHeaders,
      data: body,
      url: requestUrl,
    });
  } catch (error) {
    if (error.response.data === 'Unauthorized') {
      localStorage.clear();
      window.location.reload(false);
    }
    throw error;
  }
};
